export const INIT_STATE = "INIT_STATE"


// General
export const DISABLE_ALL_FILTERS                  = "DISABLE_ALL_FILTERS"


export const __ENABLE = "__ENABLE"
export const __DISABLE = "__DISABLE"

// Orbit type
export const ORBIT_TYPE                           = "ORBIT_TYPE"
export const ORBIT_TYPE__ENABLE                   = ORBIT_TYPE+__ENABLE
export const ORBIT_TYPE__DISABLE                  = ORBIT_TYPE+__DISABLE
export const ORBIT_TYPE__SET                      = ORBIT_TYPE+"__SET"

// Orbit height
export const ORBIT_HEIGHT                         = "ORBIT_HEIGHT"
export const ORBIT_HEIGHT__ENABLE                 = ORBIT_HEIGHT+__ENABLE
export const ORBIT_HEIGHT__DISABLE                = ORBIT_HEIGHT+__DISABLE
export const ORBIT_HEIGHT__PERIAPSIS_ENABLE       = ORBIT_HEIGHT      +"__PERIAPSIS_ENABLE"
export const ORBIT_HEIGHT__PERIAPSIS_DISABLE      = ORBIT_HEIGHT      +"__PERIAPSIS_DISABLE"
export const ORBIT_HEIGHT__SET_PERIAPSIS_RULE     = ORBIT_HEIGHT +"__SET_PERIAPSIS_RULE"
export const ORBIT_HEIGHT__SET_PERIAPSIS_MIN      = ORBIT_HEIGHT  +"__SET_PERIAPSIS_MIN"
export const ORBIT_HEIGHT__SET_PERIAPSIS_MAX      = ORBIT_HEIGHT  +"__SET_PERIAPSIS_MAX"
export const ORBIT_HEIGHT__SET_PERIAPSIS_RANGE    = ORBIT_HEIGHT+"__SET_PERIAPSIS"


export const ORBIT_HEIGHT__APOAPSIS_ENABLE        = ORBIT_HEIGHT   +"__APOAPSIS_ENABLE"
export const ORBIT_HEIGHT__APOAPSIS_DISABLE       = ORBIT_HEIGHT   +"__APOAPSIS_DISABLE"
export const ORBIT_HEIGHT__SET_APOAPSIS_RULE      = ORBIT_HEIGHT   +"__SET_APOAPSIS_RULE"
export const ORBIT_HEIGHT__SET_APOAPSIS_MIN       = ORBIT_HEIGHT   +"__SET_APOAPSIS_MIN"
export const ORBIT_HEIGHT__SET_APOAPSIS_MAX       = ORBIT_HEIGHT   +"__SET_APOAPSIS_MAX"
export const ORBIT_HEIGHT__SET_APOAPSIS_RANGE     = ORBIT_HEIGHT   +"__SET_APOAPSIS"

export const ORBIT_HEIGHT__SET_BOTH               = ORBIT_HEIGHT   +"__SET_BOTH"

// Orbit inclination
export const ORBIT_INCLINATION                    = "ORBIT_INCLINATION"
export const ORBIT_INCLINATION__ENABLE            = ORBIT_INCLINATION+__ENABLE
export const ORBIT_INCLINATION__DISABLE           = ORBIT_INCLINATION+__DISABLE
export const ORBIT_INLINCATION__SET_RULE          = ORBIT_INCLINATION+"__SET_RULE"
export const ORBIT_INCLINATION__SET_MIN           = ORBIT_INCLINATION+"__SET_MIN"
export const ORBIT_INCLINATION__SET_MAX           = ORBIT_INCLINATION+"__SET_MAX"
export const ORBIT_INCLINATION__SET_RANGE         = ORBIT_INCLINATION+"__SET_RANGE"

// Orbit duration
export const ORBIT_DURATION                       = "ORBIT_DURATION"
export const ORBIT_DURATION__ENABLE               = ORBIT_DURATION+__ENABLE
export const ORBIT_DURATION__DISABLE              = ORBIT_DURATION+__DISABLE
export const ORBIT_DURATION__SET_RULE             = ORBIT_DURATION+"__SET_RULE"
export const ORBIT_DURATION__SET_MIN              = ORBIT_DURATION+"__SET_MIN"
export const ORBIT_DURATION__SET_MAX              = ORBIT_DURATION+"__SET_MAX"
export const ORBIT_DURATION__SET_RANGE            = ORBIT_DURATION+"__SET_RANGE"

// Launch year
export const LAUNCH_YEAR                          = "LAUNCH_YEAR"
export const LAUNCH_YEAR__ENABLE                  = LAUNCH_YEAR+__ENABLE
export const LAUNCH_YEAR__DISABLE                 = LAUNCH_YEAR+__DISABLE
export const LAUNCH_YEAR__SET_RULE                = LAUNCH_YEAR+"__SET_RULE"
export const LAUNCH_YEAR__SET_YEAR                = LAUNCH_YEAR+"__SET_YEAR"
export const LAUNCH_YEAR__SET_MIN                 = LAUNCH_YEAR+"__SET_MIN"
export const LAUNCH_YEAR__SET_MAX                 = LAUNCH_YEAR+"__SET_MAX"
export const LAUNCH_YEAR__SET_RANGE               = LAUNCH_YEAR+"__SET_RANGE"

// Revolutions
export const REVOLUTIONS                          = "REVOLUTIONS"
export const REVOLUTIONS__ENABLE                  = REVOLUTIONS+__ENABLE
export const REVOLUTIONS__DISABLE                 = REVOLUTIONS+__DISABLE
export const REVOLUTIONS__SET_RULE                = REVOLUTIONS+"__SET_RULE"
export const REVOLUTIONS__SET_MIN                 = REVOLUTIONS+"__SET_MIN"
export const REVOLUTIONS__SET_MAX                 = REVOLUTIONS+"__SET_MAX"
export const REVOLUTIONS__SET_RANGE               = REVOLUTIONS+"__SET_RANGE"

// Inclination
export const INCLINATION                          = "INCLINATION"
export const INCLINATION__ENABLE                  = INCLINATION+__ENABLE
export const INCLINATION__DISABLE                 = INCLINATION+__DISABLE
export const INCLINATION__SET_RULE                = INCLINATION+"__SET_RULE"
export const INCLINATION__SET_MIN                 = INCLINATION+"__SET_MIN"
export const INCLINATION__SET_MAX                 = INCLINATION+"__SET_MAX"
export const INCLINATION__SET_RANGE               = INCLINATION+"__SET_RANGE"


// Ascending node
export const ASCENDING_NODE                       = 'ASCENDING_NODE'
export const ASCENDING_NODE__ENABLE               = ASCENDING_NODE+__ENABLE
export const ASCENDING_NODE__DISABLE              = ASCENDING_NODE+__DISABLE
export const ASCENDING_NODE__SET_RULE             = ASCENDING_NODE+"__SET_RULE"
export const ASCENDING_NODE__SET_MIN              = ASCENDING_NODE+"__SET_MIN"
export const ASCENDING_NODE__SET_MAX              = ASCENDING_NODE+"__SET_MAX"
export const ASCENDING_NODE__SET_RANGE            = ASCENDING_NODE+"__SET_RANGE"


// Mass
export const MASS                                 = "MASS"
export const MASS__ENABLE                         = MASS+__ENABLE
export const MASS__DISABLE                        = MASS+__DISABLE
export const MASS__SET_RULE                       = MASS+"__SET_RULE"
export const MASS__SET_MIN                        = MASS+"__SET_MIN"
export const MASS__SET_MAX                        = MASS+"__SET_MAX"
export const MASS__SET_RANGE                      = MASS+"__SET_RANGE"

// Contractor
export const CONTRACTOR                           = "CONTRACTOR"
export const CONTRACTOR__ENABLE                   = CONTRACTOR+__ENABLE
export const CONTRACTOR__DISABLE                  = CONTRACTOR+__DISABLE
export const CONTRACTOR__SET                      = CONTRACTOR+"__SET"

// Classification
export const CLASSIFICATION                       = "CLASSIFICATION"
export const CLASSIFICATION__ENABLE               = CLASSIFICATION+__ENABLE
export const CLASSIFICATION__DISABLE              = CLASSIFICATION+__DISABLE
export const CLASSIFICATION__SET                  = CLASSIFICATION+"__SET"


// Search
export const SEARCH                               = "SEARCH"
export const SEARCH__ENABLE                       = SEARCH+__ENABLE
export const SEARCH__DISABLE                      = SEARCH+__DISABLE
export const SEARCH__SET                          = SEARCH+"__SET"

export const RULE_EQUAL                           = "="
export const RULE_GREATER_THAN                    = "<"
export const RULE_LOWER_THAN                      = ">"
export const RULE_BETWEEN                         = "> <"
export const RULE_NONE_SET                        = " "

// Selection
export const SELECTION                            = "SELECTION"
export const SELECTION__SET_SELECTED_SATS         = SELECTION+"__SET_SELECTED_SATS"
