import React from 'react'
import _ from 'lodash'

const OrbitPaths = React.memo(({
  positions,
  line,
  yScale,
  chart,
  otherSatellites,
  theme,
}) => {
  return (
    <g>
    {positions.map((pos, i) => {
      return (
        <g key={i}>
          <path
            d={line(pos)}
            stroke={theme.calculateColorCssRGB(_.get(otherSatellites[i], 'colorScale', 0))}
            strokeWidth={1.5}
            opacity={0.5 + 0.5 / positions.length}
            fill="transparent"
            />
          <text
            x={chart.width + 5}
            y={yScale(pos[pos.length - 1].y)}
            style={{
              alignmentBaseline: 'middle',
              fill: theme.calculateColorCssRGB(_.get(otherSatellites[i], 'colorScale', 0))
            }}
            fontSize={11}
            >
            {otherSatellites[i].name}
          </text>
        </g>
      )
    })}
    </g>
  )
})

export default OrbitPaths
