import React from 'react'
import { Vec3 } from 'ogl'
import * as d3 from 'd3'
import _ from 'lodash'
import XTicks from './XTicks'
import OrbitPaths from './OrbitPaths'
import useMousePosition from '@react-hook/mouse-position'

let scaling = 1000

const width = 750
const height = 200
const margins = {
  left: 10,
  right: 300,
  top: 20,
  bottom: 25
}
const chart = {
  width: width - margins.left - margins.right,
  height: height - margins.top - margins.bottom
}
let xRange = [0,0]
let xScale = d3.scaleTime()
  .domain(xRange)
  .range([0, chart.width])

let yMax = 0
let yRange = [0, yMax]
let yScale = d3.scaleLinear()
  .domain(yRange)
  .range([chart.height, 0])

let prevUpdateTime = -1

let positions = []
let all_x = []
let all_y = []

let line = d3.line()
let lineNoScale = d3.line()
  .x(d => d.x)
  .y(d => d.y)
let firstSatellite = {}
let otherSatellites = []
const DistanceComparisonGraph = React.memo(({
  propagatedOrbits,
  latestUpdateTime,
  draggingOrbit,
  theme,
  compareAtInterval,
  setCompareAtInterval
}) => {
  const [mousePosition, ref] = useMousePosition(0, 0, 60)
  if(prevUpdateTime !== latestUpdateTime) {
    prevUpdateTime = latestUpdateTime
    firstSatellite = propagatedOrbits[0]
    otherSatellites = propagatedOrbits.filter((p, i) => i !== 0)
    if(firstSatellite === undefined
        || firstSatellite.propagatedPosition === undefined
        || otherSatellites.some(p => p.propagatedPosition === undefined)
      ) {
      return (
        <div>
        </div>
      )
    }
    let comparisonPosition = firstSatellite.propagatedPosition.map(pos => {

      return new Vec3(_.get(pos, 'pos.x', 0) / scaling, _.get(pos, 'pos.y', 0) / scaling, _.get(pos, 'pos.z', 0) / scaling)
    })

    all_x = []
    all_y = []
    positions = otherSatellites.map(sat => {
      return sat.propagatedPosition.map((pos, i) => {
        let v = new Vec3(_.get(pos, 'pos.x', 0) / scaling, _.get(pos, 'pos.y', 0) / scaling, _.get(pos, 'pos.z', 0) / scaling)
        let d = v.distance(comparisonPosition[i])
        let x = new Date(pos.time).getTime()
        all_x.push(x)
        all_y.push(d)
        return {
          x: new Date(pos.time),
          y: d
        }
      })
    })
    let _xRange = [_.min(all_x), _.max(all_x)]
    if(!_.isEqual(xRange, _xRange)) {
      xRange = _xRange
      xScale = d3.scaleTime()
        .domain(xRange)
        .range([0, chart.width])
    }
    yMax = Math.ceil(_.max(all_y))
    yRange = [0, yMax]
    yScale = d3.scaleLinear()
      .domain(yRange)
      .range([chart.height, 0])
    line = d3.line()
      .x(d => xScale(d.x))
      .y(d => yScale(d.y))
  }

  let comparisonLineX = _.clamp(mousePosition.x-margins.left - 1, 0, chart.width)

  return (
    <div className='orbit-distance-comparison-graph'>
      <div className='svg-container'>
      <svg ref={ref} width={width} height={height}
        onMouseMove={(e) => {
        const newValue = _.clamp((mousePosition.x-margins.left)/chart.width, 0.01, 0.99)
        if(newValue !== compareAtInterval) {
          setCompareAtInterval(newValue)
        }
        }}
        onMouseLeave={() => {
          setCompareAtInterval(false)
        }}
        >
      <g transform={`translate(${margins.left}, ${margins.top})`}>
        <XTicks xScale={xScale} chart={chart} theme={theme} />
        <OrbitPaths
          positions={positions}
          line={line}
          yScale={yScale}
          chart={chart}
          otherSatellites={otherSatellites}
          theme={theme}
          />
      {mousePosition.x !== null && !draggingOrbit ? (
        <line
          x1={comparisonLineX}
          x2={comparisonLineX}
          y1={0}
          y2={chart.height}
          stroke={theme.secondaryHighlightCSS}
          strokeWidth={2}
          />
      ) : null}
      <path
        d={lineNoScale([{x: 1, y: 0}, {x: 1, y: chart.height + 4}])}
        strokeWidth={1}
        stroke={theme.fill_dark}
        strokeOpacity={0.5}
        />
      <text
        x={0}
        y={-5}
        fill={theme.fill_dark}
        fontSize={11}
        >
        {yMax}.000 km
      </text>
      <path
        d={lineNoScale([{x: -5, y: chart.height}, {x: chart.width + 111, y: chart.height}])}
        strokeWidth={1}
        stroke={theme.highlight}
        />
      <rect
        x={chart.width + 110} y={chart.height - 30} width={170} height={32} rx={1} ry={1}
        fill={theme.highlight}
        />
      <text
        x={chart.width + 115}
        y={chart.height - 18}
        fill="white"
        fontSize={11}
        >
        Comparing distance to:
      </text>
      <text
        x={chart.width + 115}
        y={chart.height-4}
        fill="white"
        fontSize={11}
        >
        {firstSatellite.name} trajectory
      </text>
      </g>
      </svg>
      </div>
    </div>
  )
})

export default DistanceComparisonGraph
