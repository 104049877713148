export const earthRadius = 6371 / 1000

export const earthRotationInHours = 23 + 56/60 + 4 / 60 / 60

export const orbitTypes = {
  LEO: {
    range: [0, 2000]
  },
  MEO: {
    range: [2000, 36000]
  },
  GEO: {
    range: [36000, 37000]
  },
  HEO: {
    range: [37000, 1500000]
  }
}

export const cameraZoomLevels = [
  {
    level: 'LEO'
  },
  {
    level: 'MEO'
  },
  {
    level: 'GEO'
  },
  {
    level: "HEO"
  }
  // {
  //   level: 'LUNAR'
  // }
]

export const orbitComparisonRenderTypes = [
  "Wireframe",
  "Plane"
]
