import React from 'react'

const Classification = React.memo(({filter, filtersDispatch}) => {
  return (
    <div>
    Classification filter component
    </div>
  )
})

export default Classification
