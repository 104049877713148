import React from 'react'
import './ascending-node.css'
import {
  ascendingNodeSetRuleAction,
  ascendingNodeSetMinAction,
  ascendingNodeSetMaxAction,
  ascendingNodeSetRangeAction
} from 'filterReducer/actions'

import {
  RULE_GREATER_THAN,
  RULE_BETWEEN,
  RULE_LOWER_THAN,
  RULE_NONE_SET
} from 'filterReducer/constants'

import {
  Input,
  Button
} from 'semantic-ui-react'

const rules = [
  RULE_GREATER_THAN,
  RULE_BETWEEN,
  RULE_LOWER_THAN
]

const AscendingNode = React.memo(({
  filter,
  filtersDispatch
}) => {
  return (
    <div className='filter ascending-node'>
      <div>
        <div className='rule-bar'>
          {rules.map((rule, i) => {
            return (
              <Button
                size='mini'
                toggle
                onClick={() => {
                  if(filter.rule === rule) {
                    filtersDispatch(ascendingNodeSetRuleAction(RULE_NONE_SET))
                  } else {
                    filtersDispatch(ascendingNodeSetRuleAction(rule))
                  }
                }}
                active={filter.rule === rule && filter.enabled}
                key={i}
                >
                {rule}
              </Button>
            )
          })}
        </div>
        <Input
          size="mini"
          value={filter.range.min}
          onChange={(e) => {
            let value = parseFloat(e.target.value)
            if(!isNaN(value)) {
              filtersDispatch(ascendingNodeSetMinAction(value))
            }
          }}
          style={{left: 0}}
          disabled={!filter.enabled || filter.rule === RULE_LOWER_THAN || filter.rule === RULE_NONE_SET}
          type="number" />
        <Input
          size="mini"
          value={filter.range.max}
          disabled={!filter.enabled || filter.rule === RULE_GREATER_THAN || filter.rule === RULE_NONE_SET}
          onChange={(e) => {
            let value = parseFloat(e.target.value)
            if(!isNaN(value)) {
              filtersDispatch(ascendingNodeSetMaxAction(value))
            }
          }}
          style={{right: 0}}
          type="number" />
      </div>
      <div className='filter-suggestions'>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(ascendingNodeSetRangeAction({min: 0, max: 45}))
          }}
          >
          0-45 deg
        </Button>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(ascendingNodeSetRangeAction({min: 45, max: 90}))
          }}
          >
          45-90 deg
        </Button>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(ascendingNodeSetRangeAction({min: 90, max: 135}))
          }}
          >
          90-135 deg
        </Button>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(ascendingNodeSetRangeAction({min: 135, max: 180}))
          }}
          >
          135-180 deg
        </Button>
      </div>
      <div className='filter-suggestions'>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(ascendingNodeSetRangeAction({min: 180, max: 225}))
          }}
          >
          180-225 deg
        </Button>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(ascendingNodeSetRangeAction({min: 225, max: 270}))
          }}
          >
          225-270 deg
        </Button>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(ascendingNodeSetRangeAction({min: 270, max: 315}))
          }}
          >
          270-315 deg
        </Button>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(ascendingNodeSetRangeAction({min: 315, max: 360}))
          }}
          >
          315-360 deg
        </Button>
      </div>
    </div>
  )
})

export default AscendingNode
