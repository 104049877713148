import {
  Color
} from 'ogl'
import chroma from 'chroma-js'

let themeObject = {
  main_text_light: 'white',
  mainColorScale: [new Color(0,0,0), new Color(1,1,1)],
  seconddaryHighlight: new Color(1, 1, 0),
  calculateColor: function(value) {
    const chromaMix = chroma.mix(
      this.mainColorScale[0],
      this.mainColorScale[1],
      value)._rgb
    let mixedColor = new Color(
      chromaMix[0],
      chromaMix[1],
      chromaMix[2]
    )
    return mixedColor
  },
  calculateColorCssRGB: function(value) {
    let color = this.calculateColor(value)
    return `rgb(${color[0] * 255}, ${color[1] * 255}, ${color[2] * 255})`
  }
}


let defaultTheme = {
  ...themeObject,
  name: 'default',
  _background: new Color(1, 1, 1),
  main: 'black',
  inverse: 'white',
  fill: '#e0e1e2',
  fill_hover: '#cacbcd',
  fill_dark: "rgba(100,100,100,0.8)",
  fill_dark_hover: "rgba(0,0,0,0.8)",
  highlight: 'rgb(0,100,200)',
  highlight_hover: 'rgb(0,120,240)',
  seconddaryHighlight: new Color(1, 0.7, 0),
  mainColorScale: [new Color(0.0, 0.2, 0.4), new Color(0.2, 0.6 ,1.5 )]
}


let blackWhiteTheme = {
  ...themeObject,
  name:'black & white',
  _background: new Color(0, 0, 0),
  main: 'white',
  inverse: "black",
  fill: "rgb(100,100,100)",
  fill_hover: "rgb(120,120,120)",
  fill_dark: "rgb(200,200,200)",
  fill_dark_hover: "rgb(220,220,220)",
  highlight: "rgba(255,255,255,0.5)",
  highlight_hover: "rgb(220,220,220)",
  seconddaryHighlight: new Color(0.8, 0.5, 1),
  mainColorScale: [new Color(0.8,0.8,0.8), new Color(1,1,1)]
}

let bladeRunnerTheme = {
  ...themeObject,
  name: "Blade Runner inspired colors",
  _background: new Color(0/255, 4/255, 8/255),
  main: 'white',
  inverse: "#022025",
  fill: "#3D8C91",
  fill_hover: "#BBDCD5",
  fill_dark: "#025462",
  fill_dark_hover: '#3D8C91',
  highlight: '#F2A30F',
  highlight_hover: '#BF4C0A',
  seconddaryHighlight: new Color(1, 1, 1),
  // mainColorScale: [new Color(2/255,84/255,98/255), new Color(231/255, 166/255,61/255)],
  mainColorScale: [new Color(2/255,84/255,98/255), new Color(187/255, 220/255,213/255)]
}


let extremeColorsTestTheme = {
  ...themeObject,
  name: 'Extreme colors - test theme',
  _background: new Color(0.5, 1, 0.5),
  main: 'rgb(50,155,50)',
  inverse: 'rgb(55,0,255)',
  fill: "rgb(255,200,200)",
  fill_hover: "rgb(255,150,150)",
  fill_dark: "rgb(255,100,100)",
  fill_dark_hover: "rgb(255,0,0)",
  highlight: 'rgb(255,255,0)',
  highlight_hover: "rgb(200,200,0)",
  seconddaryHighlight: new Color(1, 0.2, 0.6),
  mainColorScale: [new Color(1,0,1), new Color(1,1,0)]
}

let _themes = [
  defaultTheme,
  bladeRunnerTheme,
  blackWhiteTheme,
  extremeColorsTestTheme
]

_themes.forEach(theme => {
  theme.background_25 = toCssRgba(theme._background, 0.25)
  theme.background_50 = toCssRgba(theme._background, 0.50)
  theme.background_75 = toCssRgba(theme._background, 0.75)
  theme.background_90 = toCssRgba(theme._background, 0.90)
  theme.background = toCssRgba(theme._background, 1)
  theme.secondaryHighlightCSS = toCssRgba(theme.seconddaryHighlight, 1)
})

function toCssRgba(color, alpha) {
  return `rgba(${color[0] * 255}, ${color[1] * 255}, ${color[2] * 255}, ${alpha})`
}

export const themes = _themes
