import {
  ASCENDING_NODE,
  ASCENDING_NODE__ENABLE,
  ASCENDING_NODE__DISABLE,
  ASCENDING_NODE__SET_RULE,
  ASCENDING_NODE__SET_MIN,
  ASCENDING_NODE__SET_MAX,
  ASCENDING_NODE__SET_RANGE,

  RULE_BETWEEN,
  RULE_NONE_SET
} from '../constants'

const defaultState = {
  name: "ascendingNode",
  enabled: false,
  const: ASCENDING_NODE,
  rule: RULE_NONE_SET,
  range: {
    min: 0,
    max: 180
  }
}

const ascendingNode = (prevState = defaultState, action) => {
  if(action === undefined) {
    return prevState
  }

  switch (action.type) {
    case ASCENDING_NODE__ENABLE:
      return {
        ...prevState,
        enabled: true
      }
    case ASCENDING_NODE__DISABLE:
      return {
        ...prevState,
        enabled: false
      }
    case ASCENDING_NODE__SET_RULE:
      return {
        ...prevState,
        enabled: true,
        rule: action.payload
      }
    case ASCENDING_NODE__SET_MIN:
      return {
        ...prevState,
        enabled: true,
        range: {
          ...prevState.range,
          min: action.payload
        }
      }
    case ASCENDING_NODE__SET_MAX:
      return {
        ...prevState,
        enabled: true,
        range: {
          ...prevState,
          max: action.payload
        }
      }
    case ASCENDING_NODE__SET_RANGE:
      return {
        ...prevState,
        enabled: true,
        rule: RULE_BETWEEN,
        range: action.payload
      }
    default:
      return prevState
  }
}

export default ascendingNode
