export const vertex = /* glsl */ `
    precision highp float;
    precision highp int;
    attribute vec3 position;
    attribute vec3 normal;
    uniform mat4 modelViewMatrix;
    uniform mat4 projectionMatrix;
    uniform mat3 normalMatrix;
    
    varying vec3 vNormal;
    void main() {
        vNormal = normalize(normalMatrix * normal);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;


export const fragment = /* glsl */ `
    precision highp float;
    precision highp int;
    uniform vec3 uColor;
    varying vec3 vNormal;
    uniform float uOpacity;
    void main() {
        vec3 normal = normalize(vNormal);
        float lighting = dot(normal, normalize(vec3(-0.3, 0.3, 0.3)));
        gl_FragColor = vec4( uColor + lighting * 0.1  -0.3, uOpacity);
    }
`;
