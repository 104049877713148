import React from 'react'
import {
  Button
} from 'semantic-ui-react'

import { setOrbitTypeAction } from '../../../filterReducer/actions'



const OrbitType = React.memo(({filter, filtersDispatch, smallSize}) => {
  function toggleType(type) {
    // mapping over the filters and toggle the one that was clicked
    filtersDispatch(setOrbitTypeAction(filter.types.map(t => {
      return {
        ...t,
        enabled: t.label === type.label ? !t.enabled : t.enabled
      }
    })))
  }
  return (
    <div>
    {filter.types.map(type => {
      return (
        <Button
          size={smallSize ? "tiny" : "small"}
          key={type.label}
          toggle
          active={type.enabled}
          onClick={() => { toggleType(type) }}
          >
          {type.label}
        </Button>
      )
    })}
    </div>
  )
})

export default OrbitType
