import React from 'react'

const ChevronLeft = () => {
  return (
    <>
    <polyline points="15 6 9 12 15 18" />
    </>
  )
}

export default ChevronLeft
