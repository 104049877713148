import {
  LAUNCH_YEAR,
  LAUNCH_YEAR__ENABLE,
  LAUNCH_YEAR__DISABLE,
  LAUNCH_YEAR__SET_RULE,
  LAUNCH_YEAR__SET_YEAR,
  LAUNCH_YEAR__SET_MIN,
  LAUNCH_YEAR__SET_MAX,
  LAUNCH_YEAR__SET_RANGE,

  RULE_EQUAL,
  RULE_BETWEEN,
  RULE_NONE_SET
} from '../constants'

const defaultState = {
  name: "Launch year",
  enabled: false,
  const: LAUNCH_YEAR,
  rule: RULE_NONE_SET,
  range: {
    min: 2019,
    max: 2020
  }
}

const launchYear = (prevState = defaultState, action) => {
  if(action === undefined) {
    return prevState
  }
  switch (action.type) {
    case LAUNCH_YEAR__ENABLE:
      return {
        ...prevState,
        enabled: true
      }
    case LAUNCH_YEAR__DISABLE:
      return {
        ...prevState,
        enabled: false
      }
    case LAUNCH_YEAR__SET_RULE:
      return {
        ...prevState,
        enabled: true,
        rule: action.payload
      }
    case LAUNCH_YEAR__SET_YEAR:
      return {
        ...prevState,
        enabled: true,
        rule: RULE_EQUAL,
        range: {
          ...prevState.range,
          min: action.payload
        }
      }
    case LAUNCH_YEAR__SET_MIN:
      return {
        ...prevState,
        enabled: true,
        range: {
          ...prevState.range,
          min: action.payload
        }
      }
    case LAUNCH_YEAR__SET_MAX:
      return {
        ...prevState,
        enabled: true,
        range: {
          ...prevState.range,
          max: action.payload
        }
      }
    case LAUNCH_YEAR__SET_RANGE:
      return {
        ...prevState,
        enabled: true,
        rule: RULE_BETWEEN,
        range: action.payload
      }
    default:
      return prevState
  }
}

export default launchYear
