import React from 'react'

const ChevronRight = () => {
  return (
    <>
    <polyline points="9 6 15 12 9 18" />
    </>
  )
}

export default ChevronRight
