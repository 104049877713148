import React from 'react'
import MailchimpSubscribe from './MailchimpSubscribe'

const url = "https://space-search.us19.list-manage.com/subscribe/post?u=8a73784871c5773b293b44a76&amp;id=89e9b9c7a0"

const MailSubscribe = () => {
  return (
    <div className='mail-subscribe'>
      <MailchimpSubscribe url={url} />
    </div>
  )
}

export default MailSubscribe
