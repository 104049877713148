import {
  Geometry,
  Program,
  Color,
  Mesh
} from 'ogl'

import {
  vertexOrbit300,
  fragmentOrbit300,
  vertexOrbit100,
  fragmentOrbit100
} from '../shaders/orbitShaders'

import {
  renderer,
  gl
} from '../earthViewRenderer'

import _ from 'lodash'

let program = false

export function satsAsGLPoints(satellites, renderComponents, time) {
  let scene = renderComponents.scene
  checkProgram()
  let previousPoints = _.find(scene.children, p => _.get(p, 'type', 'not-an-orbit') === 'satellite-points')
  if(previousPoints !== undefined) {
    scene.removeChild(previousPoints)
  }
  if(satellites.length > 0) {
    let indices = []
    let uv = []
    let colors = []
    const vertices = satellites.reduce((arr, p, i) => {
      colors.push(p.color.r)
      colors.push(p.color.g)
      colors.push(p.color.b)
      arr.push(_.get(p, 'pointPos.x',0) / 1000)
      arr.push(_.get(p, 'pointPos.z',0) / 1000)
      arr.push(_.get(p, 'pointPos.y',0) / -1000)

      indices.push(i)
      uv.push(0)
      uv.push(0)


      return arr
    }, [])
    const geometry = new Geometry(gl, {
      position: {
        size: 3,
        data: new Float32Array(vertices)
      },
      uv: {
        size: 2,
        data: new Float32Array(uv)
      },
      index: {
        data: new Uint16Array(indices)
      },
      normal: { size: 3, data: new Float32Array(vertices) },
      pathColor: {size: 3, data: new Float32Array(colors)},
    })

    const pointsMesh = new Mesh(gl, { mode: gl.POINTS, geometry, program})
    pointsMesh.setParent(scene)
    pointsMesh.type = 'satellite-points'


  }

  return {
    mesh: 'mesh'
  }
}

export function updateSatsAsGLPoints() {
  return {
    mesh: 'mesh'
  }
}

function checkProgram() {
  if(program === false) {
    program = new Program(gl, {
          vertex: renderer.isWebgl2 ? vertexOrbit300 : vertexOrbit100,
          fragment: renderer.isWebgl2 ? fragmentOrbit300 : fragmentOrbit100,
          uniforms: {
              uTime: {value: 0},
              uOpacity: { value: 0.9 },
              uColor: {value: new Color(
                0.9,
                0.0,
                0.5
                )
              }
          },
      });
  }
}
