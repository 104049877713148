function storeMousePosition(e, mousePos, mousePosInPix) {
  let doc = {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight
  }
  mousePosInPix = {
    x: e.clientX,
    y: e.clientY
  }
  let yOffset = 0
  if(doc.height < doc.width) {
    yOffset = (doc.width - doc.height) * 0.5
  }
  let y = yOffset + e.clientY
  mousePos.x = (e.clientX / doc.width - 0.5) * 2
  if(yOffset !== 0) {
    mousePos.y = (y / doc.width - 0.5) * -2
  } else {
    mousePos.y = ((doc.width - e.clientY) / doc.width - 0.5) * 2
  }
  return mousePosInPix
}

export default storeMousePosition
