export const SET_LINE_WIDTH                     = "SET_LINE_WIDTH"
export const SET_CAMERA_ZOOM_LEVEL              = "SET_CAMERA_ZOOM_LEVEL"
export const SET_CAMERA_LOOK_AT_POINT           = "SET_CAMERA_LOOK_AT_POINT"
export const SET_EARTH_TEXTURE_OPACITY          = "SET_EARTH_TEXTURE_OPACITY"
export const SET_EARTH_WIREFRAME_OPACITY        = "SET_EARTH_WIREFRAME_OPACITY"
export const SET_ORBIT_PATH_OPACITY             = "SET_ORBIT_PATH_OPACITY"
export const SET_ANIMATION_MULTIPLIER           = "SET_ANIMATION_MULTIPLIER"
export const TOGGLE_UI_VISIBILITY               = "TOGGLE_UI_VISIBILITY"
export const SET_THEME                          = "SET_THEME"
export const SET_ORBIT_COMPARISON_RENDER_TYPE   = "SET_ORBIT_COMPARISON_RENDER_TYPE"
