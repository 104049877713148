import React from 'react'
import {
  Button
} from 'semantic-ui-react'
import './applied-filters-summary.css'
import _ from 'lodash'
import {
  disableAllFilters
} from 'filterReducer/actions'


const AppliedFiltersSummary = React.memo(({
  filters,
  filtersDispatch
}) => {
  let filterKeys = _.keys(filters).filter(p => p !== 'search')
  let filtersAsArray = filterKeys.map(key => filters[key])
  const enabledCount = filtersAsArray.filter(p => p.enabled).length
  return (
    <div className='applied-filters-summary'>
    {enabledCount > 0 ? (
      <p>
      {enabledCount} filters enabled
      <Button
        size="tiny" basic
        onClick={() => {
          filtersDispatch(disableAllFilters())
        }}
        >
      Disable all filters
      </Button>
      </p>
    ) : null}
    </div>
  )
})

export default AppliedFiltersSummary
