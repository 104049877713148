import React, { useRef, useState, useEffect } from 'react'
import _ from 'lodash'
import earthViewRenderer from './render-functions/earthViewRenderer'
import Controls from './components/Controls'

import Overlay from './components/Overlay'
import './earth-view.css'
import {
  Button
} from 'semantic-ui-react'
import debounce from 'utils/debounce'

let earthRendererProps = {}

const currentTime = new Date().getTime()

const EarthView = React.memo(({
  results,
  sgp4WorkerInstanceDataIsSet,
  hoveringSearchResults,
  searchResultsHoveredSatellite,
  earthViewHoveredSatellite,
  setEarthViewHoveredSatellite,
  satActions,
  selectedSatellites,
  compareOrbits,
  compareAtInterval,
  propagatedOrbits,
  hideUI,
  theme,
  setTheme,
  draggingOrbit,
  setDraggingOrbit,
  filtersDispatch,
  renderSettings,
  renderSettingsDispatch
}) => {
  const ref = useRef(null)
  const [timeOffset, setTimeOffset] = useState(0) // time offset in seconds from current time
  const [messageVisibility, setMessageVisibility] = useState(true)
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const [overlaySatellites, setOverlaySatellites] = useState([])
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 100)
    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })
  const width = dimensions.width
  const height = width
  let satellitesToVisualise = results
  useEffect(() => {
    const newEarthRendererProps = {
      sats: satellitesToVisualise,
      hoveringSearchResults: hoveringSearchResults,
      searchResultsHoveredSatellite: searchResultsHoveredSatellite,
      earthViewHoveredSatellite: earthViewHoveredSatellite,
      setOverlaySatellites: setOverlaySatellites,
      satActions: satActions,
      selectedSatellites: selectedSatellites,
      currentTime: currentTime,
      timeOffset: timeOffset,
      sgp4WorkerInstanceDataIsSet: sgp4WorkerInstanceDataIsSet,
      compareOrbits: compareOrbits && selectedSatellites.length > 1,
      compareAtInterval: compareAtInterval,
      propagatedOrbits: propagatedOrbits,
      setDraggingOrbit: setDraggingOrbit,
      renderSettings: renderSettings,
    }
    if(!_.isEqual(earthRendererProps, newEarthRendererProps)) {
      earthRendererProps = newEarthRendererProps
      earthViewRenderer(ref.current, {
        width: width * 2,
        height: height * 2
      }, earthRendererProps)
    }

  }, [
    width,
    height,
    satellitesToVisualise,
    hoveringSearchResults,
    searchResultsHoveredSatellite,
    earthViewHoveredSatellite,
    setEarthViewHoveredSatellite,
    satActions,
    selectedSatellites,
    timeOffset,
    sgp4WorkerInstanceDataIsSet,
    compareOrbits,
    compareAtInterval,
    propagatedOrbits,
    setDraggingOrbit,
    renderSettings
  ])
  return (
    <div id="earth-view" className='earth-view'>
      <Controls
        renderSettings={renderSettings}
        renderSettingsDispatch={renderSettingsDispatch}
        hideUI={hideUI}
        setTheme={setTheme}
        setTimeOffset={setTimeOffset}
        width={width}
        />
      {satellitesToVisualise.length > 1000 && messageVisibility ? (
        <div className='top-message-bar'>
          <p className='not-rendering-orbits-message'>
          <strong>1000+</strong> objects found<br/>
          <strong>Reduced rendering and interactivity to maintain performance</strong>
          </p>
          <div>
          <Button size="mini" className='hide-message-button' onClick={() => { setMessageVisibility(false) }}>hide alert</Button>
          </div>
        </div>
      ) : null}
      <div className='visualisation'>

        <div className='earth-canvas-container' ref={ref}>
        <div id='earth-view-overlay-container'>
          <Overlay
            satellites={overlaySatellites.length < 200 ? overlaySatellites : []}
            selectedSatellites={selectedSatellites}
            searchResultsHoveredSatellite={searchResultsHoveredSatellite}
            hoveringSearchResults={hoveringSearchResults}
            satActions={satActions}
            width={width}
            height={height}
            draggingOrbit={draggingOrbit}
            filtersDispatch={filtersDispatch}
            renderSettings={renderSettings}
            renderSettingsDispatch={renderSettingsDispatch}
            />
        </div>
        <style>{`.earth-canvas-container canvas {
          width: ${width}px !important;
          height: ${height}px !important;
        }`}</style>
        </div>
      </div>
    </div>
  )
})

EarthView.defaultProps = {
  data: []
}

export default EarthView
