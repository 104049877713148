import {
  INCLINATION,
  INCLINATION__ENABLE,
  INCLINATION__DISABLE,
  INCLINATION__SET_RULE,
  INCLINATION__SET_MIN,
  INCLINATION__SET_MAX,
  INCLINATION__SET_RANGE,

  RULE_BETWEEN,
  RULE_NONE_SET
} from '../constants'

const defaultState = {
  name: "inclination",
  enabled: false,
  const: INCLINATION,
  rule: RULE_NONE_SET,
  range: {
    min: 0,
    max: 180
  }
}

const inclination = (prevState = defaultState, action) => {
  if(action === undefined) {
    return prevState
  }

  switch (action.type) {
    case INCLINATION__ENABLE:
      return {
        ...prevState,
        enabled: true
      }
    case INCLINATION__DISABLE:
      return {
        ...prevState,
        enabled: false
      }
    case INCLINATION__SET_RULE:
      return {
        ...prevState,
        enabled: true,
        rule: action.payload
      }
    case INCLINATION__SET_MIN:
      return {
        ...prevState,
        enabled: true,
        range: {
          ...prevState.range,
          min: action.payload
        }
      }
    case INCLINATION__SET_MAX:
      return {
        ...prevState,
        enabled: true,
        range: {
          ...prevState,
          max: action.payload
        }
      }
    case INCLINATION__SET_RANGE:
      return {
        ...prevState,
        enabled: true,
        rule: RULE_BETWEEN,
        range: action.payload
      }
    default:
      return prevState
  }
}

export default inclination
