import React from 'react'
import {Button} from 'semantic-ui-react'
function SearchSuggestions({
  searchQuery,
  categories
}) {
  let categoryFilterSuggestions = categories.map(category => {
    return {
      ...category,
      tags: category.tags.filter(tag => tag.includes(searchQuery))
    }
  }).filter(category => category.tags.length > 0)

  let showCosparCheatSheet = false
  if(
    searchQuery.startsWith("cospar")
    || searchQuery.startsWith("COSPAR")
    || searchQuery.match(/^\d/)) {
    showCosparCheatSheet = true
  }

  return (
    <div style={{ marginTop: 10}}>
      <p style={{fontSize: 11}}>suggestions</p>
      {showCosparCheatSheet ? (
        <div>
        <p>Searching for Cospar nr, format: {`YYLLLAA`}</p>
        <p>YY = last two digits of launch year</p>
        <p>LLL = launch number of the year</p>
        <p>AA = piece of the launch</p>
        </div>
      ) : (
        <div style={{display: 'flex'}}>
        {categoryFilterSuggestions.map(category => {
          return (
            <div key={category.label}>
            {category.tags.length > 1 ? (
              <div style={{marginRight: 10, display: 'flex'}}>
              <p style={{marginTop: 10, marginRight: 10}}>Filter by {category.name} </p>
              <div style={{height: 200, overflowY: 'auto', pointerEvents: 'auto'}}>
              {category.tags.map(tag => {
                return (
                  <div key={tag} style={{marginBottom: 3, marginRight: 40}}>
                    <Button>
                    {tag}
                    </Button>
                  </div>
                )
              })}</div>
              </div>
            ) : (
              <Button style={{marginRight: 10}}>
                <span style={{fontWeight:100}}>Filter by {category.name}</span> <span style={{fontWeight: 700}}>{category.tags}</span>
              </Button>
            )}

            </div>
          )
        })}
        </div>
      )}

    </div>
  )
}

export default SearchSuggestions
