import React from 'react'
import './filters.css'
import { filterKeys } from '../../filterReducer'
import Classification from './Classification'
import Contractor from './Contractor'
import LaunchYear from './LaunchYear'
import Mass from './Mass'
import OrbitDuration from './OrbitDuration'
import OrbitHeight from './OrbitHeight'
import OrbitType from './OrbitType'
import Revolutions from './Revolutions'
import Inclination from './Inclination'
import AscendingNode from './AscendingNode'
import FilterToggleButton from './FilterToggleButton'
import {
  enableFilterAction,
  disableFilterAction
} from '../../filterReducer/actions'
import {
  Button
} from 'semantic-ui-react'
import {
  useWindowWidth
} from '@react-hook/window-size'

const Filters = React.memo(({
  filters,
  filtersDispatch,
  showFilterTab,
  setShowFilterTab,
  contractors
}) => {
  const windowWidth = useWindowWidth()
  const smallThreshold = 796
  let topHideButtonClass = 'top-hide-button'
  if(showFilterTab) {
    topHideButtonClass += ' showing'
  }
  return (
    <div className='filters-list'>
    <Button
      className={topHideButtonClass}
      style={{width: '100%', marginTop: 25, marginBottom: 25}}
      size="small"
      onClick={() => {
      setShowFilterTab(false)
      window.scrollTo(0, 0);
      }}>
      Hide filters
    </Button>
    {filterKeys.map(key => {
      let filter = filters[key]
      let component = null
      if(key === 'orbitType') {
        component = (
          <OrbitType filter={filter} filtersDispatch={filtersDispatch} smallSize={windowWidth <= smallThreshold} />
        )
      } else if (key === 'orbitHeight') {
        component = (
          <OrbitHeight filter={filter} filtersDispatch={filtersDispatch} smallSize={windowWidth <= smallThreshold} />
        )
      } else if (key === 'orbitDuration') {
        component = (
          <OrbitDuration filter={filter} filtersDispatch={filtersDispatch} smallSize={windowWidth <= smallThreshold} />
        )
      } else if (key === 'launchYear') {
        component = (
          <LaunchYear filter={filter} filtersDispatch={filtersDispatch} smallSize={windowWidth <= smallThreshold} />
        )
      } else if (key === 'revolutions') {
        component = (
          <Revolutions filter={filter} filtersDispatch={filtersDispatch} smallSize={windowWidth <= smallThreshold} />
        )
      } else if (key === 'mass') {
        component = (
          <Mass filter={filter} filtersDispatch={filtersDispatch} smallSize={windowWidth <= smallThreshold} />
        )
      } else if (key === 'contractor') {
        component = (
          <Contractor contractors={contractors} filter={filter} filtersDispatch={filtersDispatch} smallSize={windowWidth <= smallThreshold} />
        )
      } else if (key === 'classification') {
        component = (
          <Classification filter={filter} filtersDispatch={filtersDispatch} smallSize={windowWidth <= smallThreshold} />
        )
      } else if (key === 'inclination') {
        component = (
          <Inclination filter={filter} filtersDispatch={filtersDispatch} smallSize={windowWidth <= smallThreshold} />
        )
      } else if (key === 'ascendingNode') {
        component = (
          <AscendingNode filter={filter} filtersDispatch={filtersDispatch} smallSize={windowWidth <= smallThreshold} />
        )
      } else {
        return null
      }
      let headingClassName = 'filter-header'
      if(filter.enabled === false) {
        headingClassName += ' disabled'
      }
      return (
        <div key={key}>
          <div className='controls-container'>
            <div style={{
              position: 'relative',
              opacity: filter.enabled ? 1 : 0.5,}}>
              <h3 onClick={() => { if(filter.enabled) {
                filtersDispatch(disableFilterAction(filter))
              } else {
                filtersDispatch(enableFilterAction(filter))
              } }} className={headingClassName}>{filter.name}</h3>
              <div style={{position: 'absolute', top: 5, right: 5}}>
              <FilterToggleButton
                filter={filter}
                filtersDispatch={filtersDispatch}
                />
                </div>
            </div>
            {component}
          </div>
        </div>
      )
    })}
    <Button style={{width: '100%', marginTop: 25}} size="small" onClick={() => {
      setShowFilterTab(false)
    }}>Hide filters</Button>
    </div>
  )
})

export default Filters
