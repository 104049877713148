import {
  REVOLUTIONS,
  REVOLUTIONS__ENABLE,
  REVOLUTIONS__DISABLE,
  REVOLUTIONS__SET_RULE,
  REVOLUTIONS__SET_MIN,
  REVOLUTIONS__SET_MAX,
  REVOLUTIONS__SET_RANGE,

  RULE_BETWEEN,
  RULE_NONE_SET
} from '../constants'

const defaultState = {
  name: "revolutions",
  enabled: false,
  const: REVOLUTIONS,
  rule: RULE_NONE_SET,
  range: {
    min: 500,
    max: 600
  }
}

const revolutions = (prevState = defaultState, action) => {
  if(action === undefined) {
    return prevState
  }
  switch (action.type) {
    case REVOLUTIONS__ENABLE:
      return {
        ...prevState,
        enabled: true
      }
    case REVOLUTIONS__DISABLE:
      return {
        ...prevState,
        enabled: false
      }
    case REVOLUTIONS__SET_RULE:
      return {
        ...prevState,
        enabled: true,
        rule: action.payload
      }
    case REVOLUTIONS__SET_MIN:
      return {
        ...prevState,
        enabled: true,
        range: {
          ...prevState.range,
          min: action.payload
        }
      }
    case REVOLUTIONS__SET_MAX:
      return {
        ...prevState,
        enabled: true,
        range: {
          ...prevState.range,
          max: action.payload
        }
      }
    case REVOLUTIONS__SET_RANGE:
      return {
        ...prevState,
        enabled: true,
        rule: RULE_BETWEEN,
        range: action.payload
      }
    default:
      return prevState
  }
}

export default revolutions
