import _ from 'lodash'

export function removeOrbits(scene) {
  while(findInScene(scene, 'orbit')) {
    let orbitChild = findInScene(scene, 'orbit')
    scene.removeChild(orbitChild)
  }
}

export function removeSatChildren(scene, satellites, ids = []) {
  while (findInScene(scene, 'sat-sphere')) {
    let orbitChild = findInScene(scene, 'sat-sphere')
    scene.removeChild(orbitChild)
  }
  if(ids.length > 0) {
    satellites = satellites.filter(p => !ids.includes(p.id))
    while (findInSceneWithId(scene, 'orbit', ids)) {
      let orbitChild = findInSceneWithId(scene, 'orbit', ids)
      scene.removeChild(orbitChild)
    }
  } else {
    while (findInScene(scene, 'orbit')) {
      let orbitChild = findInScene(scene, 'orbit')
      scene.removeChild(orbitChild)
    }
  }
  removeSatPoints(scene)

}

export function removeSatPoints(scene) {
  let previousPoints = findInScene(scene, 'satellite-points')
  if(previousPoints !== undefined) {
    scene.removeChild(previousPoints)
  }
}

export function removeOrbitComparePlanes(scene) {
  while(findInScene(scene, 'orbit-compare-plane')) {
    scene.removeChild(findInScene(scene, 'orbit-compare-plane'))
  }
}

export function removeCompareAtPaths(scene) {
  while(findInScene(scene, 'compare-at-path')) {
    scene.removeChild(findInScene(scene, 'compare-at-path'))
  }
}


function findInScene(scene, object) {
  return _.find(
    scene.children,
    p => _.get(p, 'type', false) === object
  )
}

function findInSceneWithId(scene, object, ids) {
  return _.find(
    scene.children,
    p => _.get(p, 'type', false) === object
    && ids.includes(_.get(p, 'sat_id'))
  )
}
