function setBatchSize(length) {
  if(length > 1000) {
    return 100
  } else if (length > 500) {
    return 40
  } else if(length > 100) {
    return 20
  }
  return 10
}

export default setBatchSize
