import React from 'react'
import MailSubscribe from './MailSubscribe'
import './footer.css'

const Footer = React.memo(() => {
  return (
    <div className='footer'>
      <div className='footer-col page-title'>
        <p><a href='https://dialectic.design/project/space-search-io'>About Space-Search.io</a></p>
      </div>
      <div className='footer-col twitter-link'>
        <p>For updates follow{" "}
        <a href='https://twitter.com/spacesearchio'>@spacesearchio</a>
      </p>
      </div>
      <div className='footer-col mail-subscribe-col'>
        <p>or subscribe</p>
        <MailSubscribe />
      </div>
    </div>
  )
})

export default Footer
