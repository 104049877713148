import React from 'react'
import moment from 'moment'

const today = new Date()

const XTicks = React.memo(({
  xScale,
  chart,
  theme
}) => {
  let xTicks = xScale.ticks(5)
  return (
    <g>
    {xTicks.map((tick, i) => {
      const date = moment(tick)
      const daysDiff = date.diff(today, 'days')
      return (
        <g key={i}>
          <line
            x1={xScale(tick)}
            x2={xScale(tick)}
            y1={chart.height - 2}
            y2={chart.height + 4}
            stroke={theme.fill_dark}
            strokeWidth={1}
            />
        {daysDiff > 0 ? (
          <text
            key={i}
            x={xScale(tick)}
            y={chart.height + 5}
            fontSize={11}
            style={{alignmentBaseline: 'hanging'}}
            fill={theme.fill_dark}
            >
            {date.format('HH:mm')} +{daysDiff}d
          </text>
        ) : (
          <text
            key={i}
            x={xScale(tick)}
            y={chart.height + 5}
            fontSize={11}
            style={{alignmentBaseline: 'hanging'}}
            fill={theme.fill_dark}
            >
            {date.format('HH:mm')}
          </text>
        )}

        </g>
      )
    })}
    </g>
  )
})

export default XTicks
