import {
  SEARCH__SET
} from '../constants'
import getParams from '../../utils/getParams'
import _ from 'lodash'

const params = getParams(window.location.href)
let urlSearchParam = _.get(params, 'search', false)

const defaultState = {
  text: urlSearchParam ? urlSearchParam : " ",
  enabled: urlSearchParam ? true : false,
}

const search = (prevState = defaultState, action) => {
  if(action === undefined) {
    return prevState
  }
  if(action.type === SEARCH__SET) {
    return {
      ...prevState,
      text: action.payload,
      enabled: true
    }
  }
  return prevState
}

export default search
