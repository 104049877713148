import {
  Renderer,
  Camera,
  Transform,
  Vec3,
  Vec2,
  Raycast
} from 'ogl'
import CustomOrbit from '../components/CustomOrbit'
import { createSatelliteGeometries } from './elements/satelliteComponents'
import createOrbitComparePath from './elements/createOrbitComparePath'
import createCompareAtPath from './elements/createCompareAtPath'
import { satsAsGLPoints } from './elements/satPositionsAsGLPoints'
import _ from 'lodash'
import { sgp4WorkerInstance } from '../../../workers/workers'
import createEarthElements from './elements/createEarthElements'
import {
  checkZoomLevel
} from './earthViewFunctions'

import {
  removeSatChildren,
  removeOrbits,
  removeSatPoints,
  removeOrbitComparePlanes,
  removeCompareAtPaths
} from './sceneFunctions'

import storeMousePosition from './functions/storeMousePosition'
import setBatchSize from './functions/setBatchSize'
import updateSatPropagation from './functions/updateSatPropagation'
import updateSatPosition from './functions/updateSatPosition'
import updateOverlayPositions from './functions/updateOverlayPositions'
import {gstime, eciToEcf} from 'satellite.js'
import chroma from 'chroma-js'

export const renderer = new Renderer({
  alpha: true,
  premultipliedAlpha: false,
  cullFace: false
})
export const gl = renderer.gl
// gl.enable(gl.DEPTH_TEST);
// gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

export const raycast = new Raycast(gl);
let backgroundColor = chroma('white')

gl.clearColor(
  backgroundColor.rgb()[0],
  backgroundColor.rgb()[1],
  backgroundColor.rgb()[2],
  1)
gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

export const camera = new Camera(gl, { fov: 35, near: 1, far: 10000})
camera.position.set(0, 20, 49)
camera.lookAt([0,0,0])

let cameraLookAtPoint = [0,0,0]

const scene = new Transform()

export let earthElements = createEarthElements({gl: gl, renderer: renderer, scene: scene})

let today = new Date()
let currentTime = today.getTime()
let positionEcf = eciToEcf({x: 6371, y: 0, z: 0}, gstime(today))
let earthRotation = Math.atan2(positionEcf.y, positionEcf.x)

// Toggle which is set to true when the canvas element has been created
let canvasCreated = false

// Toggle which is set to true when the animation loop has started
let animating = false

// variable to hold the Orbit controls object
let controls = false

let currentSatelliteIDs = []

// Array which maintains a list of IDs that have been sent to the worker
// New satellite ids are checked first with this array so that they aren't sent twice
let satsSentToWorker = []

let sentPointsToWorker = false

let satellites = []
let satellitesByID = {}
let sat2DLocations = []

let satsToBeAdded = []

let selectedSatellites = []

let searchResultsHoveredSatellite = false
let hoveringSearchResults = false
let earthViewHoveredSatellite = false

let satActions = false

let currentZoom = false


let timeOffset = 0
let satsAsGLPointsAdded = false

let previousWorldViewMatrix = camera.projectionViewMatrix.slice()
let previous2dPositionCount = 0


let mousePosInPix = { x: 0, y: 0 }
let mouseDownPosition = { x: 0, y: 0 }
const dragThreshold = 3
let mousePos = new Vec2(0,0)
let lastMousePos = new Vec2(0,0)
let propagationWorkerDataIsSet = false
let mouseDown = false
let draggingOrbit = false
let setDraggingOrbit = () => { console.log('setDraggingOrbit function still needs to be set'); }
let setOverlaySatellites = () => {
  console.log('set overlay positions function still needs to be set');
}

let compareOrbits = false
let propagatedOrbits = []

let lineWidth = 1

let animationMultiplier = false

let compareOrbitPathAdded = false

let earthTextureOpacity = 1
let earthWireFrameOpacity = 0.4
let orbitPathOpacity = 0.8
let theme = false

let compareAtInterval = false
let compareAtLineAdded = false
let orbitComparisonRenderType = false

function earthViewRenderer(canvasContainer, size, props) {
  // Initialise or update canvas & view settings
  const newRenderSettings = props.renderSettings
  checkZoomLevel(props.renderSettings.cameraZoomLevel, controls, currentZoom)
  currentZoom = props.renderSettings.cameraZoomLevel.level
  checkCanvas(canvasContainer)
  renderer.setSize(size.width, size.height)
  propagationWorkerDataIsSet = _.get(props, 'sgp4WorkerInstanceDataIsSet', false)
  // Copy props to local variables
  let results = props.sats
  if(searchResultsHoveredSatellite !== props.searchResultsHoveredSatellite) {
    searchResultsHoveredSatellite = props.searchResultsHoveredSatellite
    checkOverlayUpdate()
  }
  animationMultiplier = newRenderSettings.animationMultiplier
  hoveringSearchResults = props.hoveringSearchResults
  setOverlaySatellites = props.setOverlaySatellites
  satActions = props.satActions
  earthViewHoveredSatellite = props.earthViewHoveredSatellite


  earthTextureOpacity = newRenderSettings.earthTextureOpacity
  earthWireFrameOpacity = newRenderSettings.earthWireFrameOpacity

  if(orbitPathOpacity !== newRenderSettings.orbitPathOpacity) {
    orbitPathOpacity = newRenderSettings.orbitPathOpacity
    let satWithOrbitGeometries = _.find(satellites, (p => _.isArray(p.geometries)))
    if(satWithOrbitGeometries !== undefined) {
      satWithOrbitGeometries.geometries[0].program.uniforms.uOpacity = {value: orbitPathOpacity}
    }
  }

  setDraggingOrbit = props.setDraggingOrbit
  if(selectedSatellites !== props.selectedSatellites) {
    selectedSatellites = props.selectedSatellites
    removeOrbitComparePlanes(scene)
  }

  compareOrbits = props.compareOrbits


  let updateOrbitComparisonPlane = false
  if(orbitComparisonRenderType !== newRenderSettings.orbitComparisonRenderType) {
    orbitComparisonRenderType = newRenderSettings.orbitComparisonRenderType
    updateOrbitComparisonPlane = true
  }

  if(propagatedOrbits !== props.propagatedOrbits) {
    propagatedOrbits = props.propagatedOrbits
    updateOrbitComparisonPlane = true
  }

  if(updateOrbitComparisonPlane) {
    removeOrbitComparePlanes(scene)
    compareOrbitPathAdded = false
  }



  if(compareAtInterval !== props.compareAtInterval) {
    removeCompareAtPaths(scene)
    compareAtInterval = props.compareAtInterval
    compareAtLineAdded = false
  }


  if(newRenderSettings.lineWidth !== lineWidth) {
    lineWidth = newRenderSettings.lineWidth
    satellites = []
    currentSatelliteIDs = []
    removeOrbits(scene)
  }

  if(cameraLookAtPoint !== props.renderSettings.cameraLookAtPoint) {
    cameraLookAtPoint = props.renderSettings.cameraLookAtPoint
    controls.target = cameraLookAtPoint
  }
  if(newRenderSettings.theme.name !== theme.name) {
    theme = newRenderSettings.theme
    let themeBackgroundColor = newRenderSettings.theme._background
    gl.clearColor(
      themeBackgroundColor[0],
      themeBackgroundColor[1],
      themeBackgroundColor[2],
      1)
    satellites = satellites.map(sat => {
      return {
        ...sat,
        color:theme.calculateColor(sat.colorScale)
      }
    })
  }

  // reset the worker trackers
  satsSentToWorker = []
  sentPointsToWorker = false

  // prepare the update to the Scene
  let newIds = results.map(sat => sat.id)
  if(results.length === 0) {
    removeSatChildren(scene, satellites)
  }

  if(!_.isEqual(currentSatelliteIDs.sort(), newIds.sort())) {
    satsAsGLPointsAdded = false

    /**
     * Removing satellites which are not in the search results anymore
     */
    let satsToRemove = _.difference(currentSatelliteIDs, newIds)
    let satsToRemoveIDs = satsToRemove.map(sat => sat.id)
    if(satsToRemove.length > 0) {
      removeSatChildren(scene, satellites, satsToRemove)
      // filtering the satellites and ids arrays with the sats to remove
      satellites = satellites.filter(sat => satsToRemoveIDs.includes(sat.id))
      currentSatelliteIDs = currentSatelliteIDs.filter(id => satsToRemoveIDs.includes(id))
    }
    /**
     * Preparing new satellites for addition to the scene
     */
    let newSatIds = newIds.filter(id => !currentSatelliteIDs.includes(id))
    satsToBeAdded = results.filter(sat => newSatIds.includes(sat.id)).map(sat => {
      return {...sat,
        positionAvailable: false,
        pointPosAvailable: false,
        rendered: false
      }
    })

    // updating the satellites array with the new satellites
    satellites = satellites.concat(satsToBeAdded)

    // Grouping the satellites by ID for quick access to
    satellitesByID = _.groupBy(satellites.map(p => {
      return {
        id: p.id,
        name: p.name,
        positionAvailable: false,
        pointPosAvailable: false,
        color: p.color,
        periapsis: p.periapsis,
        apoapsis: p.apoapsis,
        tle: p.tle
      }
    }), 'id')
    for(const key in satellitesByID) {
      satellitesByID[key] = satellitesByID[key][0]
    }
    currentSatelliteIDs = newIds
  }
  if(animating === false) {
    requestAnimationFrame(update)
    animating = true
  }


}

function checkIfSatHasBeenSentToWorker(id) {
  return !satsSentToWorker.includes(id)
}

function updateSatPropagationWrapper(propagatedSatellite) {
  updateSatPropagation(satellites, propagatedSatellite)
}

function update(t) {
  if(satellites.length < 1000) {
    let satsSentToSGPWorker = false
    let positionBatchSize = setBatchSize(satellites.length)
    while(_.find(satellites, p => p.positionAvailable === false) && satsSentToSGPWorker === false) {
      let satIDsWithoutPositionBatch = satellites.filter(p => p.positionAvailable === false)
        .filter((p, i) => i < positionBatchSize).map(p => p.id)
        .filter(checkIfSatHasBeenSentToWorker)

      if(propagationWorkerDataIsSet && satIDsWithoutPositionBatch.length > 0) {
        satsSentToWorker = satsSentToWorker.concat(satIDsWithoutPositionBatch)
        sgp4WorkerInstance.propagateSatelliteIDs(satIDsWithoutPositionBatch, currentTime + timeOffset).then(returnMessage => {
          if(returnMessage.success) {
            returnMessage.data.forEach(updateSatPropagationWrapper)
          }
        })
      }
      satsSentToSGPWorker = true
    }
    let orbitElementsBatchSize = 3
    let addedGeometriesThisFrame = 0
    while(_.find(satellites, p => p.rendered === false && p.positionAvailable === true) && addedGeometriesThisFrame < orbitElementsBatchSize) {
      let sat = _.find(satellites, p => p.rendered === false && p.positionAvailable === true)
      sat.geometries = createSatelliteGeometries(sat, {
        gl: gl,
        scene: scene,
        lineWidth: lineWidth,
        orbitPathOpacity: orbitPathOpacity
      })
      sat.rendered = true
      addedGeometriesThisFrame += 1
    }

  } else {
    //satsAsGLPointsAdded = false
    removeOrbits(scene)
  }


  if(compareOrbitPathAdded === false && compareOrbits) {

    if(propagatedOrbits.length > 0
      && propagatedOrbits.every(p => p.propagatedPosition !== undefined)
      && propagatedOrbits.every(p => p.color !== undefined)
      && propagatedOrbits.every(p => p.color !== false)) {

      let orbitComparePath = createOrbitComparePath(propagatedOrbits,
        {
          gl: gl,
          scene: scene,
          lineWidth: lineWidth,
          renderType: orbitComparisonRenderType
        })

      orbitComparePath.setParent(scene)
      compareOrbitPathAdded = true
    }

  }

  if(compareAtLineAdded === false && compareAtInterval) {
    compareAtLineAdded = true
    if(propagatedOrbits.length > 0
      && propagatedOrbits.every(p => p.propagatedPosition !== undefined)
      && propagatedOrbits.every(p => p.color !== undefined)
      && propagatedOrbits.every(p => p.color !== false)) {
      createCompareAtPath(compareAtInterval, propagatedOrbits,
        {
          gl: gl,
          scene: scene,
          lineWidth: lineWidth,
          color: theme.seconddaryHighlight
        })
    }
  }

  if(satsAsGLPointsAdded === false
    && currentSatelliteIDs.length > 0
    && propagationWorkerDataIsSet
    && sentPointsToWorker === false) {
      sentPointsToWorker = true
      sgp4WorkerInstance.getSatellitePositions(currentSatelliteIDs, currentTime + timeOffset)
        .then(returnMessage => {
          if(returnMessage.success) {
            returnMessage.data.forEach(propagatedSatellite => {
              updateSatPosition(satellitesByID, {
                ...propagatedSatellite,
                color:theme.calculateColor(propagatedSatellite.colorScale)
              })
            })
            removeSatPoints(scene)
            satsAsGLPoints(currentSatelliteIDs.map(id => satellitesByID[id]).filter(p => p.pointPosAvailable), {gl: gl, scene: scene}, currentTime + timeOffset)
            satsAsGLPointsAdded = true
          }
        })
  }




  // update earth rotation
  const rotation = earthRotation
  earthElements.earthWireframe.rotation.y = - rotation
  earthElements.earth.rotation.y = - rotation
  earthElements.earth.program.uniforms.uOpacity.value = earthTextureOpacity
  earthElements.earthWireframe.program.uniforms.uOpacity.value = earthWireFrameOpacity
  if(controls) {
    controls.update(animationMultiplier)
  }

  checkOverlayUpdate()
  renderer.render({scene, camera})
  //gl.clearColor(1, 1, 1, 1);
  //gl.colorMask(false, false, false, true);
  //gl.clear(gl.COLOR_BUFFER_BIT);
  requestAnimationFrame(update)

}

function checkOverlayUpdate(force = false) {
  const previousMatrixValues = previousWorldViewMatrix.map(p => Math.round(p * 100)/100)
  const currentWorldViewValues = camera.projectionViewMatrix.map(p => Math.round(p * 100)/100)
  let update = force
  if(!_.isEqual(previousMatrixValues, currentWorldViewValues)) {
    update = true
  }
  else if((previous2dPositionCount !== currentSatelliteIDs.filter(id => satellitesByID[id].pointPosAvailable).length
    || mousePos.x !== lastMousePos.x
    || mousePos.y !== lastMousePos.y) && hoveringSearchResults === false) {
    update = true
  }
  if (update) {
    previousWorldViewMatrix = camera.projectionViewMatrix.slice()
    previous2dPositionCount = currentSatelliteIDs.filter(id => satellitesByID[id].pointPosAvailable).length
    updateOverlayPositions(sat2DLocations,
      currentSatelliteIDs,
      satellitesByID,
      previous2dPositionCount,
      searchResultsHoveredSatellite,
      selectedSatellites,
      mousePos,
      lastMousePos,
      hoveringSearchResults,
      earthViewHoveredSatellite,
      satActions,
      setOverlaySatellites,
      draggingOrbit
    )
  }
}



function checkCanvas(canvasContainer) {
  if(canvasCreated === false) {
    canvasContainer.appendChild(gl.canvas)

    document.addEventListener('mousemove', (e) => {
      mousePosInPix = storeMousePosition(e, mousePos, mousePosInPix)
      if(Math.abs(mouseDownPosition.x - mousePosInPix.x) < dragThreshold && Math.abs(mouseDownPosition.y - mousePosInPix.y) < dragThreshold) {
        if(!draggingOrbit && mouseDown) {
          draggingOrbit = true
          setDraggingOrbit(true)
          document.getElementById('earth-view').classList.add("dragging-orbit")
        }
      }
    });

    document.getElementById("earth-view").addEventListener('mousedown', () => {
      mouseDownPosition = _.clone(mousePosInPix)
      mouseDown = true
    })

    document.getElementById("earth-view").addEventListener('click', clickHandler)

    controls = new CustomOrbit(camera, {
        element: canvasContainer,
        target: new Vec3(0, 0, 0)
    })
    canvasCreated = true
  }
}



function clickHandler(e) {
  mouseDown = false

  draggingOrbit = false
  setDraggingOrbit(false)
  document.getElementById('earth-view').classList.remove("dragging-orbit")
  if(Math.abs(mouseDownPosition.x - mousePosInPix.x) < dragThreshold && Math.abs(mouseDownPosition.y - mousePosInPix.y) < dragThreshold) {
    if(earthViewHoveredSatellite) {
      if(selectedSatellites.includes(earthViewHoveredSatellite)) {
        satActions.removeSatFromSelectedSats(earthViewHoveredSatellite)
      } else {
        satActions.addSatToSelectedSats(earthViewHoveredSatellite)
      }

    }
  }
}


export default earthViewRenderer
