import React from 'react'

import {
  revolutionsSetRuleAction,
  revolutionsSetMinAction,
  revolutionsSetMaxAction,
  revolutionsSetRangeAction
} from '../../../filterReducer/actions'

import {
  RULE_GREATER_THAN,
  RULE_BETWEEN,
  RULE_LOWER_THAN,
  RULE_NONE_SET
} from '../../../filterReducer/constants'

import {
  Input,
  Button
} from 'semantic-ui-react'

import './revolutions.css'

const rules = [
  RULE_GREATER_THAN,
  RULE_BETWEEN,
  RULE_LOWER_THAN
]

const Revolutions = React.memo(({filter, filtersDispatch}) => {

  return (
    <div className='filter revolutions'>
      <div>
        <div className='rule-bar'>
        {rules.map((rule, i) => {
          return (
            <Button
              size='mini'
              toggle
              onClick={() => {
                if(filter.rule === rule) {
                  filtersDispatch(revolutionsSetRuleAction(RULE_NONE_SET))
                } else {
                  filtersDispatch(revolutionsSetRuleAction(rule))
                }

              }}
              active={filter.rule === rule && filter.enabled}
              key={i}>
              {rule}
            </Button>
          )
        })}
        </div>
        <Input
          size="mini"
          value={filter.range.min}
          onChange={(e) => {
            let value = parseFloat(e.target.value)
            if(!isNaN(value)) {
              filtersDispatch(revolutionsSetMinAction(value))
            }
          }}
          style={{left: 0}}
          disabled={!filter.enabled || filter.rule === RULE_LOWER_THAN || filter.rule === RULE_NONE_SET}
          type="number" />
        <Input
          size="mini"
          value={filter.range.max}
          disabled={!filter.enabled || filter.rule === RULE_GREATER_THAN || filter.rule === RULE_NONE_SET}
          onChange={(e) => {
            let value = parseFloat(e.target.value)
            if(!isNaN(value)) {
              filtersDispatch(revolutionsSetMaxAction(value))
            }
          }}
          style={{right: 0}}
          type="number" />
      </div>
      <div className='filter-suggestions'>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(revolutionsSetRangeAction({min: 0, max: 100}))
          }}
          >
          0-100
        </Button>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(revolutionsSetRangeAction({min: 100, max: 500}))
          }}
          >
          100-500
        </Button>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(revolutionsSetRangeAction({min: 500, max: 1000}))
          }}
          >
          500-1000
        </Button>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(revolutionsSetRangeAction({min: 1000, max: 5000}))
          }}
          >
          1k-5k
        </Button>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(revolutionsSetRangeAction({min: 5000, max: 10000}))
          }}
          >
          5k-10k
        </Button>
      </div>
      <p style={{fontSize: 9, paddingTop: 5}}>Revolutions are capped at 9999.</p>
    </div>
  )
})

export default Revolutions
