import React from 'react'
import { Button } from 'semantic-ui-react'
import { earthRadius } from 'constants/defaults'
import {
  orbitHeightSetApoapsisRangeAction,
  orbitHeightSetPeriapsisRangeAction,
  inclinationSetRangeAction,
  ascendingNodeSetRangeAction
} from 'filterReducer/actions'


const darkening = 0.85

const SatGraphs = React.memo(({
  color,
  lookAtSat,
  filtersDispatch,
  satParams
}) => {
  let buttonStyle = {color: 'white'}
  buttonStyle.background = `rgba(
    ${color.r * 255 * darkening},
    ${color.g * 255 * darkening},
    ${color.b * 255 * darkening}, ${1})`
  return (
    <div className='sat-graphs'>
    <p className='sat-control apoapsis'>Apogee <span><strong>{((satParams.apoapsis -earthRadius) * 1000).toFixed(0)}</strong>km</span></p>
    <p className='sat-control periapsis'>Perigee <span><strong>{((satParams.periapsis -earthRadius) * 1000).toFixed(0)}</strong>km</span></p>
    <p className='sat-control periapsis'>Revolutions <span><strong>{satParams.revolution}</strong></span></p>
    <Button
      style={buttonStyle}
      size="tiny"
      onClick={() => { lookAtSat() }}
      >
      Focus camera on sat
    </Button>
    <Button
      style={buttonStyle}
      size="tiny"
      onClick={() => {
        filtersDispatch(orbitHeightSetApoapsisRangeAction(
          {
            min: satParams.apoapsis - 0.1,
            max: satParams.apoapsis + 0.1
          }
        ))
        filtersDispatch(orbitHeightSetPeriapsisRangeAction(
          {
            min: satParams.periapsis - 0.1,
            max: satParams.periapsis + 0.1
          }
        ))
        filtersDispatch(inclinationSetRangeAction(
          {
            min: satParams.inclination - 2,
            max: satParams.inclination + 2
          }
        ))
        filtersDispatch(ascendingNodeSetRangeAction(
          {
            min: satParams.ascension - 2,
            max: satParams.ascension + 2
          }
        ))
        // set filter actions on:
        // Apoapsis & Periapsis
        // Inclination
      }}
      >
      Filter on similar orbits
    </Button>
    </div>
  )
})

export default SatGraphs
