import _ from 'lodash'
import classification from './filters/classification'
import contractor from './filters/contractor'
import launchYear from './filters/launchYear'
import mass from './filters/mass'
import orbitDuration from './filters/orbitDuration'
import orbitHeight from './filters/orbitHeight'
import orbitType from './filters/orbitType'
import revolutions from './filters/revolutions'
import inclination from './filters/inclination'
import ascendingNode from './filters/ascendingNode'
import search from './filters/search'
import {
  DISABLE_ALL_FILTERS,
  ORBIT_TYPE,
  ORBIT_HEIGHT,
  ORBIT_DURATION,
  INCLINATION,
  ASCENDING_NODE,
  LAUNCH_YEAR,
  REVOLUTIONS,
  MASS,
  CONTRACTOR,
  CLASSIFICATION,
  SEARCH,
  SELECTION__SET_SELECTED_SATS
} from './constants'

const filterReducer = (prevState, action) => {
  if(prevState === undefined) {
     return {
       orbitType: orbitType(),
       orbitHeight: orbitHeight(),
       orbitDuration: orbitDuration(),
       revolutions: revolutions(),
       inclination: inclination(),
       ascendingNode: ascendingNode(),
       launchYear: launchYear(),
       // mass: mass(),
       contractor: contractor(),
       // classification: classification(),
       search: search(),
       selectedSatellites: []
    }
  } else if (action.type === DISABLE_ALL_FILTERS) {
    return {
      ...prevState,
      orbitType: {...prevState.orbitType, enabled: false },
      orbitHeight: {...prevState.orbitHeight, enabled: false },
      orbitDuration: {...prevState.orbitDuration, enabled: false },
      revolutions: {...prevState.revolutions, enabled: false },
      inclination: {...prevState.inclination, enabled: false },
      ascendingNode: {...prevState.ascendingNode, enabled: false },
      launchYear: {...prevState.launchYear, enabled: false },
      contractor: {...prevState.contractor, enabled: false },
    }
  } else if(action.type.startsWith(SEARCH)) {
    return {
      ...prevState,
      search: search(prevState.search, action)
    }
  } else if(action.type.startsWith(ORBIT_TYPE)) {
    return {
      ...prevState,
      orbitType: orbitType(prevState.orbitType, action)
    }
  } else if(action.type.startsWith(ORBIT_HEIGHT)) {
    return {
      ...prevState,
      orbitHeight: orbitHeight(prevState.orbitHeight, action)
    }
  } else if(action.type.startsWith(INCLINATION)) {
    return {
      ...prevState,
      inclination: inclination(prevState.inclination, action)
    }
  } else if(action.type.startsWith(ASCENDING_NODE)) {
    return {
      ...prevState,
      ascendingNode: ascendingNode(prevState.ascendingNode, action)
    }
  } else if(action.type.startsWith(ORBIT_DURATION)) {
    return {
      ...prevState,
      orbitDuration: orbitDuration(prevState.orbitDuration, action)
    }
  } else if(action.type.startsWith(LAUNCH_YEAR)) {
    return {
      ...prevState,
      launchYear: launchYear(prevState.launchYear, action)
    }
  } else if(action.type.startsWith(REVOLUTIONS)) {
    return {
      ...prevState,
      revolutions: revolutions(prevState.revolutions, action)
    }
  } else if(action.type.startsWith(MASS)) {
    return {
      ...prevState,
      mass: mass(prevState.mass, action)
    }
  } else if(action.type.startsWith(CONTRACTOR)) {
    return {
      ...prevState,
      contractor: contractor(prevState.contractor, action)
    }
  } else if(action.type.startsWith(CLASSIFICATION)) {
    return {
      ...prevState,
      classification: classification(prevState.classification, action)
    }
  } else if(action.type.startsWith(SELECTION__SET_SELECTED_SATS)) {
    return {
      ...prevState,
      selectedSatellites: action.payload
    }
  }



  return prevState
}

let initialFilterState = filterReducer()

export const filterKeys = _.keys(initialFilterState)


export default filterReducer
