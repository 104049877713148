import React from 'react'
import {
  Button
} from 'semantic-ui-react'

import {
  orbitComparisonRenderTypes
} from 'constants/defaults'
import {
  setOrbitComparisonRenderType
} from 'renderSettingsReducer/actions'

const ComparisonRenderControls = React.memo(({
  orbitComparisonRenderType,
  renderSettingsDispatch
}) => {
  return (
    <div className='comparison-render-controls'>
    <p>Render as</p>
    {orbitComparisonRenderTypes.map((type, i) => {
      return (
        <Button
          key={i}
          toggle
          basic
          active={orbitComparisonRenderType === type}
          onClick={() => {
            renderSettingsDispatch(setOrbitComparisonRenderType(type))
          }}
          size="tiny"
          >
        {type}
        </Button>
      )
    })}
    </div>
  )
})

export default ComparisonRenderControls
