import React from 'react'

const ChevronsRight = () => {
  return (
    <>
    <polyline points="7 7 12 12 7 17" />
    <polyline points="13 7 18 12 13 17" />
    </>
  )
}

export default ChevronsRight
