import {
  Vec3
} from 'ogl'
import {
  TOGGLE_UI_VISIBILITY,
  SET_LINE_WIDTH,
  SET_CAMERA_ZOOM_LEVEL,
  SET_CAMERA_LOOK_AT_POINT,
  SET_EARTH_TEXTURE_OPACITY,
  SET_EARTH_WIREFRAME_OPACITY,
  SET_ORBIT_PATH_OPACITY,
  SET_ANIMATION_MULTIPLIER,
  SET_THEME,
  SET_ORBIT_COMPARISON_RENDER_TYPE
} from './constants'
import { themes } from 'constants/themes'
import {
  cameraZoomLevels,
  orbitComparisonRenderTypes
} from 'constants/defaults'
import getParams from 'utils/getParams'
import _ from 'lodash'

const urlParams = getParams(window.location.href)
const urlZoomLevel = _.get(urlParams, 'zoom', false)
let hideUI = _.get(urlParams, 'hideUI', false)
if(hideUI) { hideUI = true }
let defaultCameraZoom = _.find(cameraZoomLevels, zoom => zoom.level === urlZoomLevel)
if(defaultCameraZoom === undefined) defaultCameraZoom = cameraZoomLevels[0]
let animationMultiplier = _.get(urlParams, 'animationMultiplier', false)
if(animationMultiplier) { animationMultiplier = parseFloat(animationMultiplier)}
const defaultRenderSettings = {
  lineWidth: 2,
  cameraZoomLevel: defaultCameraZoom,
  cameraLookAtPoint: new Vec3(0, 0, 0),
  earthTextureOpacity: 1,
  earthWireFrameOpacity: 0.4,
  orbitPathOpacity: 0.6,
  animationMultiplier: animationMultiplier,
  hideUI: hideUI,
  theme: themes[0],
  orbitComparisonRenderType: orbitComparisonRenderTypes[0]
}

const renderSettingsReducer = (prevState = defaultRenderSettings, action) => {
  if(action === undefined) {
    return prevState
  }
  switch (action.type) {
    case SET_LINE_WIDTH:
      return {
        ...prevState,
        lineWidth: action.payload
      }
    case SET_CAMERA_ZOOM_LEVEL:
      return {
        ...prevState,
        cameraZoomLevel: action.payload
      }
    case SET_CAMERA_LOOK_AT_POINT:
      return {
        ...prevState,
        cameraLookAtPoint: action.payload
      }
    case SET_EARTH_TEXTURE_OPACITY:
      return {
        ...prevState,
        earthTextureOpacity: action.payload
      }
    case SET_EARTH_WIREFRAME_OPACITY:
      return {
        ...prevState,
        earthWireFrameOpacity: action.payload
      }
    case SET_ORBIT_PATH_OPACITY:
      return {
        ...prevState,
        orbitPathOpacity: action.payload
      }
    case SET_ANIMATION_MULTIPLIER:
      return {
        ...prevState,
        animationMultiplier: action.payload
      }
    case TOGGLE_UI_VISIBILITY:
      return {
        ...prevState,
        hideUI: !prevState.hideUI
      }
    case SET_THEME:
      return {
        ...prevState,
        theme: action.payload
      }
    case SET_ORBIT_COMPARISON_RENDER_TYPE:
      return {
        ...prevState,
        orbitComparisonRenderType: action.payload
      }
    default:
      return prevState
  }
}

export default renderSettingsReducer
