import {
  DISABLE_ALL_FILTERS,
  __ENABLE,
  __DISABLE,

  SEARCH__SET,

  ORBIT_TYPE__SET,

  ORBIT_HEIGHT__PERIAPSIS_ENABLE,
  ORBIT_HEIGHT__PERIAPSIS_DISABLE,
  ORBIT_HEIGHT__SET_PERIAPSIS_RULE,
  ORBIT_HEIGHT__SET_PERIAPSIS_MIN,
  ORBIT_HEIGHT__SET_PERIAPSIS_MAX,
  ORBIT_HEIGHT__SET_PERIAPSIS_RANGE,
  ORBIT_HEIGHT__APOAPSIS_ENABLE,
  ORBIT_HEIGHT__APOAPSIS_DISABLE,
  ORBIT_HEIGHT__SET_APOAPSIS_RULE,
  ORBIT_HEIGHT__SET_APOAPSIS_MIN,
  ORBIT_HEIGHT__SET_APOAPSIS_MAX,
  ORBIT_HEIGHT__SET_APOAPSIS_RANGE,

  ORBIT_DURATION__SET_RULE,
  ORBIT_DURATION__SET_MIN,
  ORBIT_DURATION__SET_MAX,
  ORBIT_DURATION__SET_RANGE,

  LAUNCH_YEAR__SET_RULE,
  LAUNCH_YEAR__SET_YEAR,
  LAUNCH_YEAR__SET_MIN,
  LAUNCH_YEAR__SET_MAX,
  LAUNCH_YEAR__SET_RANGE,

  REVOLUTIONS__SET_RULE,
  REVOLUTIONS__SET_MIN,
  REVOLUTIONS__SET_MAX,
  REVOLUTIONS__SET_RANGE,

  INCLINATION__SET_RULE,
  INCLINATION__SET_MIN,
  INCLINATION__SET_MAX,
  INCLINATION__SET_RANGE,

  ASCENDING_NODE__SET_RULE,
  ASCENDING_NODE__SET_MIN,
  ASCENDING_NODE__SET_MAX,
  ASCENDING_NODE__SET_RANGE,

  MASS__SET_RULE,
  MASS__SET_MIN,
  MASS__SET_MAX,
  MASS__SET_RANGE,

  CONTRACTOR__SET,

  CLASSIFICATION__SET,

  SELECTION__SET_SELECTED_SATS
} from './constants'

// general

export const disableAllFilters = () => {
  return {
    type: DISABLE_ALL_FILTERS
  }
}


// search

export const enableFilterAction = (filter) => {
  return {
    type: filter.const + __ENABLE
  }
}

export const disableFilterAction = (filter) => {
  return {
    type: filter.const + __DISABLE
  }
}

export const searchAction = (searchQuery) => {
  return {
    type: SEARCH__SET,
    payload: searchQuery
  }
}


// orbit type

export const setOrbitTypeAction = (types) => {
  return {
    type: ORBIT_TYPE__SET,
    payload: types
  }
}

// orbit height
export const orbitHeightPeriapsisEnableAction = () => {
  return { type: ORBIT_HEIGHT__PERIAPSIS_ENABLE }
}

export const orbitHeightPeriapsisDisableAction = () => {
  return { type: ORBIT_HEIGHT__PERIAPSIS_DISABLE }
}

export const orbitHeightSetPeriapsisRuleAction = (rule) => {
  return {
    type: ORBIT_HEIGHT__SET_PERIAPSIS_RULE,
    payload: rule
  }
}

export const orbitHeightSetPeriapsisMinAction = (min) => {
  return {
    type: ORBIT_HEIGHT__SET_PERIAPSIS_MIN,
    payload: min
  }
}

export const orbitHeightSetPeriapsisMaxAction = (max) => {
  return {
    type: ORBIT_HEIGHT__SET_PERIAPSIS_MAX,
    payload: max
  }
}

export const orbitHeightSetPeriapsisRangeAction = (range) => {
  return {
    type: ORBIT_HEIGHT__SET_PERIAPSIS_RANGE,
    payload: range
  }
}

export const orbitHeightApoapsisEnableAction = () => {
  return { type: ORBIT_HEIGHT__APOAPSIS_ENABLE }
}

export const orbitHeightApoapsisDisableAction = () => {
  return { type: ORBIT_HEIGHT__APOAPSIS_DISABLE }
}

export const orbitHeightSetApoapsisRuleAction = (rule) => {
  return {
    type: ORBIT_HEIGHT__SET_APOAPSIS_RULE,
    payload: rule
  }
}

export const orbitHeightSetApoapsisMinAction = (min) => {
  return {
    type: ORBIT_HEIGHT__SET_APOAPSIS_MIN,
    payload: min
  }
}

export const orbitHeightSetApoapsisMaxAction = (max) => {
  return {
    type: ORBIT_HEIGHT__SET_APOAPSIS_MAX,
    payload: max
  }
}

export const orbitHeightSetApoapsisRangeAction = (range) => {
  return {
    type: ORBIT_HEIGHT__SET_APOAPSIS_RANGE,
    payload: range
  }
}


// orbit duration

export const orbitDurationSetRuleAction = (rule) => {
  return {
    type: ORBIT_DURATION__SET_RULE,
    payload: rule
  }
}

export const orbitDurationSetMinAction = (min) => {
  return {
    type: ORBIT_DURATION__SET_MIN,
    payload: min
  }
}

export const orbitDurationSetMaxAction = (max) => {
  return {
    type: ORBIT_DURATION__SET_MAX,
    payload: max
  }
}

export const orbitDurationSetRangeAction = (range) => {
  return {
    type: ORBIT_DURATION__SET_RANGE,
    payload: range
  }
}


// launch year

export const launchYearSetRuleAction = (rule) => {
  return {
    type: LAUNCH_YEAR__SET_RULE,
    payload: rule
  }
}

export const launchYearSetYearAction = (year) => {
  return {
    type: LAUNCH_YEAR__SET_YEAR,
    payload: year
  }
}

export const launchYearSetMinAction = (min) => {
  return {
    type: LAUNCH_YEAR__SET_MIN,
    payload: min
  }
}

export const launchYearSetMaxAction = (max) => {
  return {
    type: LAUNCH_YEAR__SET_MAX,
    payload: max
  }
}

export const launchYearSetRangeAction = (range) => {
  return {
    type: LAUNCH_YEAR__SET_RANGE,
    payload: range
  }
}


// revolutions

export const revolutionsSetRuleAction = (rule) => {
  return {
    type: REVOLUTIONS__SET_RULE,
    payload: rule
  }
}

export const revolutionsSetMinAction = (min) => {
  return {
    type: REVOLUTIONS__SET_MIN,
    payload: min
  }
}

export const revolutionsSetMaxAction = (max) => {
  return {
    type: REVOLUTIONS__SET_MAX,
    payload: max
  }
}

export const revolutionsSetRangeAction = (range) => {
  return {
    type: REVOLUTIONS__SET_RANGE,
    payload: range
  }
}




// inclination

export const inclinationSetRuleAction = (rule) => {
  return {
    type: INCLINATION__SET_RULE,
    payload: rule
  }
}

export const inclinationSetMinAction = (min) => {
  return {
    type: INCLINATION__SET_MIN,
    payload: min
  }
}

export const inclinationSetMaxAction = (max) => {
  return {
    type: INCLINATION__SET_MAX,
    payload: max
  }
}

export const inclinationSetRangeAction = (range) => {
  return {
    type: INCLINATION__SET_RANGE,
    payload: range
  }
}


// ascending node
export const ascendingNodeSetRuleAction = (rule) => {
  return {
    type: ASCENDING_NODE__SET_RULE,
    payload: rule
  }
}

export const ascendingNodeSetMinAction = (min) => {
  return {
    type: ASCENDING_NODE__SET_MIN,
    payload: min
  }
}

export const ascendingNodeSetMaxAction = (max) => {
  return {
    type: ASCENDING_NODE__SET_MAX,
    payload: max
  }
}

export const ascendingNodeSetRangeAction = (range) => {
  return {
    type: ASCENDING_NODE__SET_RANGE,
    payload: range
  }
}




// mass

export const massSetRuleAction = (rule) => {
  return {
    type: MASS__SET_RULE,
    payload: rule
  }
}

export const massSetMinAction = (min) => {
  return {
    type: MASS__SET_MIN,
    payload: min
  }
}

export const massSetMaxAction = (max) => {
  return {
    type: MASS__SET_MAX,
    payload: max
  }
}

export const massSetRangeAction = (range) => {
  return {
    type: MASS__SET_RANGE,
    payload: range
  }
}




// contractor

export const setContractorsAction = (contractors) => {
  return {
    type: CONTRACTOR__SET,
    payload: contractors
  }
}





// classification

export const setClassificationAction = (classifications) => {
  return {
    type: CLASSIFICATION__SET,
    payload: classifications
  }
}


// satellite selection

export const seSelectedSatellitesAction = (satellites) => {
  return {
    type: SELECTION__SET_SELECTED_SATS,
    payload: satellites
  }
}
