import React from 'react'

import {
  orbitDurationSetRuleAction,
  orbitDurationSetMinAction,
  orbitDurationSetMaxAction,
  orbitDurationSetRangeAction
} from '../../../filterReducer/actions'

import {
  RULE_LOWER_THAN,
  RULE_GREATER_THAN,
  RULE_BETWEEN,
  RULE_NONE_SET
} from '../../../filterReducer/constants'

import {
  Input,
  Button
} from 'semantic-ui-react'

const rules = [
  RULE_GREATER_THAN,
  RULE_BETWEEN,
  RULE_LOWER_THAN
]

const OrbitDuration = React.memo(({filter, filtersDispatch}) => {
  return (
    <div className='filter'>
      <div>
        <div className='rule-bar'>
        {rules.map((rule) => {
          return (
            <Button
              size='mini'
              toggle
              onClick={() => {
                if(filter.rule === rule) {
                  filtersDispatch(orbitDurationSetRuleAction(RULE_NONE_SET))
                } else {
                  filtersDispatch(orbitDurationSetRuleAction(rule))
                }

              }}
              active={filter.rule === rule && filter.enabled}
              key={rule}>
              {rule}
            </Button>
          )
        })}
        </div>
        <Input
          size="mini"
          value={filter.range.min}
          onChange={(e) => {
            let value = parseFloat(e.target.value)
            if(!isNaN(value)) {
              filtersDispatch(orbitDurationSetMinAction(value))
            }
          }}
          style={{left: 0}}
          disabled={!filter.enabled || filter.rule === RULE_LOWER_THAN || filter.rule === RULE_NONE_SET}
          type="number" />
        <Input
          size="mini"
          value={filter.range.max}
          disabled={!filter.enabled || filter.rule === RULE_GREATER_THAN || filter.rule === RULE_NONE_SET}
          onChange={(e) => {
            let value = parseFloat(e.target.value)
            if(!isNaN(value)) {
              filtersDispatch(orbitDurationSetMaxAction(value))
            }
          }}
          style={{right: 0}}
          type="number" />
        </div>
        <div className='filter-suggestions'>
          <Button
            size="mini"
            onClick={() => {
              filtersDispatch(orbitDurationSetRangeAction({
                min: 0.9,
                max: 1.1
              }))
            }}
            >
            0.9-1.1
          </Button>
          <Button
            size="mini"
            onClick={() => {
              filtersDispatch(orbitDurationSetRangeAction({
                min: 4,
                max: 6
              }))
            }}
            >
            4-6
          </Button>
          <Button
            size="mini"
            onClick={() => {
              filtersDispatch(orbitDurationSetRangeAction({
                min: 6,
                max: 8
              }))
            }}
            >
            6-8
          </Button>
          <Button
            size="mini"
            onClick={() => {
              filtersDispatch(orbitDurationSetRangeAction({
                min: 8,
                max: 10
              }))
            }}
            >
            8-10
          </Button>
          <Button
            size="mini"
            onClick={() => {
              filtersDispatch(orbitDurationSetRangeAction({
                min: 10,
                max: 15
              }))
            }}
            >
            10-15
          </Button>
        </div>
        <p style={{fontSize: 9, paddingTop: 5}}>Revolutions per day</p>
    </div>
  )
})

export default OrbitDuration
