let resized = true

window.addEventListener('resize', () => {
  resized = true
})

export function checkZoomLevel(zoom, controls, currentZoom) {
  if(zoom.level !== currentZoom) {
    currentZoom = zoom.level
    if(controls) {
      controls.zoomTo(getZoomLevel(currentZoom))
    }
  } else if (resized) {
    if(controls) {
      resized = false
      currentZoom = zoom.level
      controls.zoomTo(getZoomLevel(currentZoom))
    }
  }
}

export function getZoomLevel(currentZoom) {
  let zoomLevel = 40
  let multiplier = 1
  if(window.innerWidth < window.innerHeight) {
    multiplier = 1 + window.innerWidth / window.innerHeight * 25
  }
    if(currentZoom === 'LEO') {
      zoomLevel = 50 - multiplier
    } else if(currentZoom === 'MEO') {
      zoomLevel = 120
    } else if (currentZoom === 'GEO') {
      zoomLevel = 200
    } else if (currentZoom === 'HEO') {
      zoomLevel = 400
    }
  return zoomLevel
}
