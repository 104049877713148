import React, { useState } from 'react'
import CameraControls from '../CameraControls'
import RenderControls from '../RenderControls'
import ThemeSelector from '../RenderControls/ThemeSelector'
import AnimationControls from '../AnimationControls'
import Timeline from '../Timeline'
import './controls.css'
import {
  Button
} from 'semantic-ui-react'
import _ from 'lodash'

const Controls = React.memo(({
  renderSettings,
  renderSettingsDispatch,
  currentTime,
  timeOffset,
  setTimeOffset,
  hideUI,
  setTheme,
  width
}) => {
  const [enabledControl, setEnabledControl] = useState(false)
  const extraControls = [
    {
      name: 'Rendering',
      component: (
        <RenderControls
          lineWidth={renderSettings.lineWidth}
          earthTextureOpacity={renderSettings.earthTextureOpacity}
          earthWireFrameOpacity={renderSettings.earthWireFrameOpacity}
          orbitPathOpacity={renderSettings.orbitPathOpacity}
          renderSettingsDispatch={renderSettingsDispatch}
          setTheme={setTheme}
          />
      )
    },
    {
      name: "Color theme",
      component: (
        <ThemeSelector setTheme={setTheme} width={width} />
      )
    },
    {
      name: 'Animation',
      component: (
        <AnimationControls
          animationMultiplier={renderSettings.animationMultiplier}
          renderSettingsDispatch={renderSettingsDispatch}
          />
      )
    }
  ]
  let enabledControlComponent = _.find(extraControls, (p) => p.name === enabledControl)
  return (
    <div className='controls-component'>
      <div className='controls' style={{opacity: hideUI ? 0 : 1}}>
        <CameraControls
          cameraZoomLevel={renderSettings.cameraZoomLevel}
          renderSettingsDispatch={renderSettingsDispatch}
          cameraLookAtPoint={renderSettings.cameraLookAtPoint}
          />
          {width > 796 ? (
            <>
            {extraControls.map((control, i) => {
              return (
                <div key={i} className='laptop-control-container'>
                {control.component}
                </div>
               )
             })}
            </>
          ) : (
            <div className='mobile-control-bar'>
            {enabledControl ? (
              <div className='control-popup'>
              {enabledControlComponent.component}
              </div>
            ) : null}
              <div className='control-group-toggle-buttons-container'>
              {extraControls.map((control, i) => {
                return (
                  <div key={i} className='mobile-control'>
                    <Button
                      size="tiny"
                      toggle
                      active={enabledControl === control.name}
                      onClick={() => {
                        if(enabledControl === control.name) {
                          setEnabledControl(false)
                        } else {
                          setEnabledControl(control.name)
                        }

                      }}
                      >
                      {control.name}
                    </Button>
                  </div>
                 )
               })}
               </div>
            </div>
          )}
      </div>
      <Timeline
        currentTime={currentTime}
        timeOffset={timeOffset}
        setTimeOffset={setTimeOffset}
        />
    </div>
  )
})

export default Controls
