import React from 'react'

import {
  enableFilterAction,
  disableFilterAction
} from '../../filterReducer/actions'

import {
  Checkbox
} from 'semantic-ui-react'

const FilterToggleButton = ({
  filter,
  filtersDispatch
}) => {
  return (
    <div className='filter-toggle-checkbox'>
    <Checkbox
      onChange={() => {
        if(filter.enabled) {
          filtersDispatch(disableFilterAction(filter))
        } else {
          filtersDispatch(enableFilterAction(filter))
        }
      }}
      checked={filter.enabled}
      />
    </div>
  )
}

export default FilterToggleButton
