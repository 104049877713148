import React from 'react'

const CevronsLeft = () => {
  return (
    <>
      <polyline points="11 7 6 12 11 17" />
      <polyline points="17 7 12 12 17 17" />
    </>
  )
}

export default CevronsLeft
