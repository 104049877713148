/**
 * Calls the function within specified timeframe,
 * if the function is called again within the timeframe
 * the previous one is canceled and a new timer is set
 * up with the new function.
 *
 * Used in for example the search box to put a delay in while typing
 * so that only after you stop typing the latest input is sent.
 */

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

export default debounce
