import {
  Program,
  Color,
  Mesh,
  Geometry,
} from 'ogl'
import {
  vertex,
  fragment
} from '../shaders/defaultShadersWithOpacity'

import {
  gl
} from '../earthViewRenderer'



let program = false

function initialiseProgram() {
  program = new Program(gl, {
     vertex,
     fragment,
     cullFace: null,
     transparent: true,
     uniforms: {
         uTime: {value: 0},
         uColor: {value: new Color(0.0, 0.0, 0.0)},
         uOpacity: {value: 0.9}
     },
 });
}


function createOrbitComparePath(orbitComparison, renderTools) {
  if(program === false) {
    initialiseProgram()
  }
  const modulo = 100
  let color = new Color(
    orbitComparison[0].color.r,
    orbitComparison[0].color.g,
    orbitComparison[0].color.b
  )
  color.forEach((value, i) => {
    let diffFromOne = 1 - value
    diffFromOne *= 0.5
    color[i] = value + diffFromOne
  })
  program.uniforms.uColor = {value: color}
  let pointsOrbit1 = orbitComparison[0].propagatedPosition.filter((p, i) => i % modulo === 0)
  let pointsOrbit2 = orbitComparison[1].propagatedPosition.filter((p, i) => i % modulo === 0)
  pointsOrbit1 = pointsOrbit1.map(p => p.pos).filter((p,i, a) => i < a.length -1)
  pointsOrbit2 = pointsOrbit2.map(p => p.pos).filter((p,i, a) => i < a.length -1)
  let trianglePoints = []
  pointsOrbit1.forEach((p, i) => {
    trianglePoints = trianglePoints.concat([
      p,
      pointsOrbit2[i]
    ])
  })
  let positionArray = trianglePoints.reduce((values, p) => {
    return values.concat([p.x/1000, p.z/1000, -p.y/1000])
  }, [])
  let uvArray = trianglePoints.reduce((values, p) => {
    return values.concat([0, 0])
  }, [])
  let indexArray = trianglePoints.reduce((values, p, i) => {
    if(i < trianglePoints.length - 3) {
      return values.concat([i, i + 1, i+2])
    } else {
      return values
    }
  }, [])
  const geometry = new Geometry(gl, {
    position: {size: 3, data: new Float32Array(positionArray) },
    normal: {size: 3, data: new Float32Array(positionArray) },
    uv: {size: 2, data: new Float32Array(uvArray) },
    index: {data: new Uint16Array(indexArray)}
  })

  let mode = gl.LINES
  if(renderTools.renderType === 'Plane') {
    mode = gl.TRIANGLES
    program.uniforms.uOpacity.value = 0.95
  } else if(renderTools.renderType === 'Wireframe') {
    mode = gl.LINES
    program.uniforms.uOpacity.value = 0.7
  }

  let triangles = new Mesh(gl,{
    mode: mode,
    geometry: geometry,
    program: program
  })
  triangles.type = 'orbit-compare-plane'
  return triangles
  // Implement triangles
}

export default createOrbitComparePath
