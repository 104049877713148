import {
  Color,
  Vec3
} from 'ogl'

import CustomPolyline from '../../components/CustomPolyline'

import {
  gl
} from '../earthViewRenderer'
import _ from 'lodash'

export function createSatelliteGeometries(satellite, renderTools) {
  let gl = renderTools.gl
  let scene = renderTools.scene
  if(satellite.positionAvailable) {
    let polylines = createLineMeshFromPoints(satellite.propagatedPosition.map(p => {
      return {
        x: _.get(p, 'pos.x', 0) / 1000,
        y: _.get(p, 'pos.z', 0) / 1000,
        z: _.get(p, 'pos.y', 0) / -1000
      }
    }), gl, scene, satellite.color, renderTools.lineWidth, renderTools.orbitPathOpacity)
    polylines.forEach(line => {
      line.mesh.type = "orbit"
      line.mesh.sat_id = satellite.id
    })

    return polylines
  }
  return false
}

function createLineMeshFromPoints(points, _gl, scene, color, lineWidth, opacity) {
  const segmentCount = 1
  return _.range(segmentCount).map(segment => {
    const pointsForSegment = points.filter((p, i, a) => _.inRange(
      i,
      a.length / segmentCount * segment,
      a.length / segmentCount * (segment + 1) + 1))

    let positionPoint = _.last(pointsForSegment)
    // let positionPoint = pointsForSegment[Math.round((pointsForSegment.length - 1) / 2)]
    positionPoint = new Vec3(positionPoint.x, positionPoint.y, positionPoint.z)
    let polyline = new CustomPolyline(gl, {
      points: pointsForSegment.map(p => {
        return new Vec3(
          p.x - positionPoint.x,
          p.y - positionPoint.y,
          p.z - positionPoint.z)
      }),
      uniforms: {
        uColor: {value: new Color(color.r, color.g, color.b)},
        uThickness: {value: lineWidth},
        uOpacity: {value: opacity}
      }
    })
    polyline.mesh.position.set(positionPoint)
    polyline.mesh.lineWidth = lineWidth
    polyline.mesh.setParent(scene)
    return polyline
  })

}

export default createSatelliteGeometries
