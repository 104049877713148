import React from 'react'

const CssGlobalProps = React.memo(({
  theme
}) => {
  return (
    <style>
    {`:root {
      --main-bg-color: ${theme.background};
      --main-bg-color-opacity-25: ${theme.background_25};
      --main-bg-color-opacity-50: ${theme.background_50};
      --main-bg-color-opacity-75: ${theme.background_75};
      --main-bg-color-opacity-90: ${theme.background_90};

      --main-text-color: ${theme.main};
      --main-text-inverse: ${theme.inverse};
      --main-text-light: ${theme.main_text_light};


      --fill: ${theme.fill};
      --fill-hover: ${theme.fill_hover};
      --fill-dark: ${theme.fill_dark};
      --fill-dark-hover: ${theme.fill_dark_hover};

      --highlight: ${theme.highlight};
      --highlight-hover: ${theme.highlight_hover};
    }`}
    </style>
  )
})

export default CssGlobalProps
