import { Vec2 } from 'ogl'
import { gl, camera, earthElements } from '../earthViewRenderer'
import _ from 'lodash'
import satMouseDistance from './satMouseDistance'
import satCameraDistance from './satCameraDistance'
import checkIfBehindEarth from './checkIfBehindEarth'
import {
  mouseDistanceThreshold
} from '../../constants'

function updateOverlayPositions(
    sat2DLocations,
    currentSatelliteIDs,
    satellitesByID,
    previous2dPositionCount,
    searchResultsHoveredSatellite,
    selectedSatellites,
    mousePos,
    lastMousePos,
    hoveringSearchResults,
    earthViewHoveredSatellite,
    satActions,
    setOverlaySatellites,
    draggingOrbit
  ) {
  if(currentSatelliteIDs.length > 1000) {
    sat2DLocations = []
  }


  lastMousePos.x = mousePos.x
  lastMousePos.y = mousePos.y
  const lastMousePosVec = new Vec2(lastMousePos.x, lastMousePos.y)
  let satWithinClickingDistance = false
  if(currentSatelliteIDs.length < 1000) {
    let overlayIDs = currentSatelliteIDs
    sat2DLocations = overlayIDs.filter(id => satellitesByID[id].pointPosAvailable)
      .map(id => {
        return satCameraDistance(satMouseDistance(satellitesByID[id], lastMousePosVec, camera), camera)
      })
  }

  let hoveredAndSelectedSatellites = sat2DLocations.filter(sat => sat.sat.id === searchResultsHoveredSatellite || selectedSatellites.includes(sat.sat.id))
  if(currentSatelliteIDs.length >= 1000) {
    let hoveredAndSelectedIDs = selectedSatellites.slice().concat(searchResultsHoveredSatellite).filter(p => p)
    hoveredAndSelectedIDs = _.uniq(hoveredAndSelectedIDs)
    hoveredAndSelectedSatellites = hoveredAndSelectedIDs.map(id => {
        return satMouseDistance(satellitesByID[id], lastMousePosVec, camera)
    })
  }
  sat2DLocations.forEach(p => {
    p.vec2DLoc = new Vec2(p.projectedVec.x, p.projectedVec.y)
  })
  // filter satellites that are behind the earth
  sat2DLocations = sat2DLocations.filter(sat => checkIfBehindEarth(sat, camera, earthElements))
  // add a toggle for the overlay satellites, so when you hover over a search result
  // it will still show up in the overlay but then with some transparency and a label.
  hoveredAndSelectedSatellites.forEach(sat => {
    sat.vec2DLoc = new Vec2(sat.projectedVec.x, sat.projectedVec.y)
    sat.behindEarth = !checkIfBehindEarth(sat, camera, earthElements)
  })

  sat2DLocations.forEach(sat => {
    if(sat.mouseDistance < mouseDistanceThreshold) {
      satWithinClickingDistance = true
    }
  })
  if(satWithinClickingDistance && draggingOrbit === false) {
    gl.canvas.style.cursor = 'pointer'
  } else {
    gl.canvas.style.cursor = 'inherit'
  }


  sat2DLocations.sort((a, b) => {
    return a.mouseDistance - b.mouseDistance
  })
  if(sat2DLocations.length > 100) {
    sat2DLocations = sat2DLocations.filter((p, i) => {
      if(p.mouseDistance > 0.2 || draggingOrbit) {
        return false
      }
      return i < 20
    })
  }

  if(hoveredAndSelectedSatellites.length > 0) {
    const ids_included = sat2DLocations.map(p => p.sat.id)
    hoveredAndSelectedSatellites = hoveredAndSelectedSatellites.filter(p => !ids_included.includes(p.sat.id))
    sat2DLocations = sat2DLocations.concat(hoveredAndSelectedSatellites)
  }
  if(sat2DLocations.length > 0 && satWithinClickingDistance && hoveringSearchResults === false && !draggingOrbit) {
    if(earthViewHoveredSatellite !== sat2DLocations[0].sat.id) {
      satActions.setEarthViewHoveredSatellite(sat2DLocations[0].sat.id)
    }
  } else {
    if(earthViewHoveredSatellite !== false) {
      satActions.setEarthViewHoveredSatellite(false)
    }
  }
  setOverlaySatellites(sat2DLocations)
}

export default updateOverlayPositions
