import React from 'react'
import SatLabel from './SatLabel'
import './overlay.css'

const Overlay = React.memo(({
  satellites,
  searchResultsHoveredSatellite,
  hoveringSearchResults,
  selectedSatellites,
  width,
  height,
  satActions,
  draggingOrbit,
  filtersDispatch,
  renderSettingsDispatch
}) => {
  if(satellites.length === 0 || satellites.length > 200) {
    return (
      <div className='overlay'>
      </div>
    )
  }
  return (
    <div className='overlay'>
    {satellites.map((sat,i) => {
      return (
        <SatLabel
          key={sat.sat.id}
          sat={sat}
          isFirst={i === 0}
          highlight={searchResultsHoveredSatellite === sat.sat.id}
          hoveringSearchResults={hoveringSearchResults}
          selected={selectedSatellites.includes(sat.sat.id)}
          width={width}
          height={height}
          satActions={satActions}
          draggingOrbit={draggingOrbit}
          renderSettingsDispatch={renderSettingsDispatch}
          filtersDispatch={filtersDispatch}
          />
      )
    })}
    </div>
  )
})


export default Overlay
