import {
  Sphere,
  Texture,
  Program,
  Color,
  Mesh
} from 'ogl'
import {
  earthRadius
} from '../../../../constants/defaults'
import {
  vertex as vertexDefault,
  fragment as fragmentDefault
} from '../shaders/defaultShaders'

import {
  vertex300 as vertexTexture300,
  fragment300 as fragmentTexture300,
  vertex100 as vertexTexture100,
  fragment100 as fragmentTexture100
} from '../shaders/textureDiffuseNormalShaders'


function createEarthElements(renderComponents) {
  let renderer = renderComponents.renderer
  let gl = renderComponents.gl
  let scene = renderComponents.scene

  const sphereGeometry = new Sphere(gl, {
    radius: earthRadius,
    widthSegments: 80
  })
  const sphereWireframeGeometry = new Sphere(gl, {
    radius: earthRadius * 1.001,
    widthSegments: 80
  })

  const earthTextureDiffuse = new Texture(gl)
  const earthImgDiffuse = new Image()
  if(renderer.isWebgl2) {
    earthImgDiffuse.src = 'assets/earth-mega-large-bw.png'
  } else {
    earthImgDiffuse.src = 'assets/earth-medium-bw.png'
  }

  earthImgDiffuse.onload = () => {
    earthTextureDiffuse.image = earthImgDiffuse
  }

  const earthTextureNormal = new Texture(gl)
  const earthImgNormal = new Image()
  earthImgNormal.src = 'assets/earth-normal.png'
  earthImgNormal.onload = () => {
    earthTextureNormal.image = earthImgNormal
  }

  const earthTexturedProgram = new Program(gl, {
    vertex: renderer.isWebgl2 ? vertexTexture300 : vertexTexture100,
    fragment: renderer.isWebgl2 ? fragmentTexture300 : fragmentTexture100,
    transparent: true,
    uniforms: {
      tDiffuse: { value: earthTextureDiffuse },
      tNormal: { value: earthTextureNormal },
      uNormalScale: { value: 1},
      uNormalUVScale: { value: 1 },
      uTime: { value: 0 },
      uOpacity: {value: 0.8}
    }
  })

  const wireframeProgram = new Program(gl, {
      vertex: vertexDefault,
      fragment: fragmentDefault,
      transparent: true,
      uniforms: {
          uTime: {value: 0},
          uColor: {value: new Color(0.8, 0.8, 0.8)},
          uOpacity: {value: 0.8}
      },
  });

  const sphereWireframe = new Mesh(gl, {
    mode: gl.LINES,
    geometry: sphereWireframeGeometry,
    program: wireframeProgram,
  })

  const earthTexturedMesh = new Mesh(gl, {
    geometry: sphereGeometry,
    program: earthTexturedProgram
  })
  earthTexturedMesh.geometry.raycast = 'sphere'

  sphereWireframe.setParent(scene)
  earthTexturedMesh.setParent(scene)
  return {
    earth: earthTexturedMesh,
    earthWireframe: sphereWireframe
  }
}

export default createEarthElements
