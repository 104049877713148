import React from 'react'
import {
  cameraZoomLevels
} from 'constants/defaults'
import {
  Button
} from 'semantic-ui-react'
import {
  Vec3
} from 'ogl'
import './camera-controls.css'
import {
  setCameraZoomLevel,
  setCameraLookAtPoint
} from 'renderSettingsReducer/actions'

const CameraControls = React.memo(({
  cameraZoomLevel,
  renderSettingsDispatch,
  cameraLookAtPoint,
}) => {
  return (
    <div className='camera-controls'>
      <p className='label'>CAMERA ZOOM</p>
      {
      cameraZoomLevels.map((level, i) => {
        return (
          <div className='button-container' key={i}>
          <Button
            size="tiny"
            toggle active={level === cameraZoomLevel}
            onClick={() => {
              renderSettingsDispatch(setCameraZoomLevel(level))
            }}>
            {level.level}
          </Button>
          </div>
        )
      })}
      {cameraLookAtPoint.some(p => p !== 0) ? (
        <>
        <div className='button-container'>
          <Button size="tiny"
            onClick={() => renderSettingsDispatch(setCameraLookAtPoint(new Vec3(0, 0, 0)))}
            >
            Reset camera
          </Button>
        </div>
        <p>Back to earth</p>
        </>
      ) : null}
    </div>
  )
})


export default CameraControls
