import React from 'react'
import { FixedSizeList as List } from 'react-window';
import _ from 'lodash'
import moment from 'moment'
import './search-results.css'

// extracting props out of scope of Searchresults
// so that they can be accessed by the react-window children
let _satActions = false
let _selectedSatellites = false
let _searchResultsHoveredSatellite = false
let _earthViewHoveredSatellite = false
let _theme = false
const SearchResults = React.memo(({
  results,
  searchResultsHoveredSatellite,
  earthViewHoveredSatellite,
  satActions,
  selectedSatellites,
  setHoveringSearchResults,
  theme
}) => {
  _satActions = satActions
  _selectedSatellites = selectedSatellites
  _searchResultsHoveredSatellite = searchResultsHoveredSatellite
  _earthViewHoveredSatellite = earthViewHoveredSatellite
  _theme = theme
  return (
    <div className='search-results'>
      <h3>Search results <span>{`(${results.length} objects found)`}</span></h3>
      <p><strong>Name</strong>, cospar id</p>
      <div className='result-list-container'
        onMouseEnter={() => { setHoveringSearchResults(true) }}
        onMouseLeave={() => { setHoveringSearchResults(false) }}
        >
        <List
          height={window.innerHeight - 200}
          itemCount={results.length}
          itemSize={62}
          width={300}
          itemData={results}
          children={Result}
          />
      </div>
    </div>
  )
})

const Result = React.memo(({
  index,
  data,
  style
}) => {
  let p = data[index]
  const hovered = p.id === _searchResultsHoveredSatellite || p.id === _earthViewHoveredSatellite
  const selected = _selectedSatellites.includes(p.id)
  let className = 'search-result'
  let elementStyle = {}
  let objectColor = _theme.calculateColor(p.colorScale)
  elementStyle.border = `2px solid rgb(${objectColor.r * 255}, ${objectColor.g * 255}, ${objectColor.b * 255})`
  if(hovered) {

    elementStyle.background = `rgba(
        ${objectColor.r * 255},
        ${objectColor.g * 255},
        ${objectColor.b * 255}, 0.4)`
    elementStyle.border = `2px solid rgb(${objectColor.r * 255},
    ${objectColor.g * 255},
    ${objectColor.b * 255})`
  }
  if(selected) {
    className += " selected"
    elementStyle.background = `rgba(
        ${objectColor.r * 255},
        ${objectColor.g * 255},
        ${objectColor.b * 255}, 1)`
  }
  return (
    <div className='react-window-container-div' style={style}>
    <div
      className={className}
      style={elementStyle}
      onMouseEnter={() => {
        _satActions.setSearchResultsHoveredSatellite(p.id)
      }}
      onMouseLeave={() => {
        _satActions.setSearchResultsHoveredSatellite(false)
      }}
      onClick={() => {
        if(selected) {
          _satActions.removeSatFromSelectedSats(p.id)
        } else {
          _satActions.addSatToSelectedSats(p.id)
        }
      }}
      >
      <p><strong>{p.celestrak_name}</strong>, {p.cospar_id_file}</p>
      <div className='extra-info'>
        <p><strong>{_.round(p.inclination, 1)}</strong>deg inclination</p>
      </div>
      <div className='data-source-date'>
      <p className='hard-date' style={{fontSize: 8}}>Data from: {p.file_date}</p>
      <p className='from-now' style={{fontSize: 8}}>Propagation using position from {moment(p.tle.date).fromNow()}</p>
      </div>
    </div>
    </div>
  )
})

export default SearchResults

/**


{p.meta ? (
  <>
    <p>{p.meta.SatelliteName}</p>
    <p>COSPAR: {p.id}</p>
  </>
) : (
  <>
  <p>{p.id}</p>
  </>
)}

 */
