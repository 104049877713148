import React from 'react'

const ChevronsDown = () => {
  return (
    <>
      <polyline points="7 7 12 12 17 7" />
      <polyline points="7 13 12 18 17 13" />
    </>
  )
}

export default ChevronsDown
