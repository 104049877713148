import React from 'react'

const Mass = React.memo(({filter, filtersDispatch}) => {
  return (
    <div>
    Mass filter component
    </div>
  )
})

export default Mass
