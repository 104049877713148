import { raycast } from '../earthViewRenderer'

function checkIfBehindEarth(sat, camera, earthElements) {
  raycast.castMouse(camera, [
    sat.vec2DLoc[0],
    sat.vec2DLoc[1]
  ])
  const hits = raycast.intersectBounds(earthElements.earth);
  if(hits.length > 0) {
    const hit = hits[0].hit
    if(camera.position.distance(sat.vecPos) > hit.distance) {
      return false
    }
  }
  return true
}

export default checkIfBehindEarth
