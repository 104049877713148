import {
  ORBIT_HEIGHT,
  ORBIT_HEIGHT__ENABLE,
  ORBIT_HEIGHT__DISABLE,
  ORBIT_HEIGHT__PERIAPSIS_ENABLE,
  ORBIT_HEIGHT__PERIAPSIS_DISABLE,
  ORBIT_HEIGHT__SET_PERIAPSIS_RULE,
  ORBIT_HEIGHT__SET_PERIAPSIS_MIN,
  ORBIT_HEIGHT__SET_PERIAPSIS_MAX,
  ORBIT_HEIGHT__SET_PERIAPSIS_RANGE,

  ORBIT_HEIGHT__APOAPSIS_ENABLE,
  ORBIT_HEIGHT__APOAPSIS_DISABLE,
  ORBIT_HEIGHT__SET_APOAPSIS_RULE,
  ORBIT_HEIGHT__SET_APOAPSIS_MIN,
  ORBIT_HEIGHT__SET_APOAPSIS_MAX,
  ORBIT_HEIGHT__SET_APOAPSIS_RANGE,

  ORBIT_HEIGHT__SET_BOTH,

  RULE_BETWEEN
} from '../constants'


const defaultState = {
  name: "Orbit height",
  enabled: false,
  const: ORBIT_HEIGHT,
  apoapsis: {
    rule: ' ',
    enabled: false,
    range: {
      min: 0,
      max: 10
    }
  },
  periapsis: {
    rule: ' ',
    enabled: false,
    range: {
      min: 0,
      max: 10
    }
  }
}

const orbitHeight = (prevState = defaultState, action) => {
  if(action === undefined) {
    return prevState
  }
  switch (action.type) {
    case ORBIT_HEIGHT__ENABLE:
      return {
        ...prevState,
        enabled: true
      }
    case ORBIT_HEIGHT__DISABLE:
      return {
        ...prevState,
        enabled: false
      }

    case ORBIT_HEIGHT__PERIAPSIS_ENABLE:
      return {
        ...prevState,
        enabled: true,
        periapsis: {
          ...prevState.periapsis,
          enabled: true
        }
      }
    case ORBIT_HEIGHT__PERIAPSIS_DISABLE:
      return {
        ...prevState,
        enabled: true,
        periapsis: {
          ...prevState.periapsis,
          rule: " ",
          enabled: false
        }
      }
    case ORBIT_HEIGHT__SET_PERIAPSIS_RULE:
      return {
        ...prevState,
        enabled: true,
        periapsis: {
          ...prevState.periapsis,
          enabled: true,
          rule: action.payload
        }
      }
    case ORBIT_HEIGHT__SET_PERIAPSIS_MIN:
      return {
        ...prevState,
        enabled: true,
        periapsis: {
          ...prevState.periapsis,
          enabled: true,
          range: {
            ...prevState.periapsis.range,
            min: action.payload
          }
        }
      }
    case ORBIT_HEIGHT__SET_PERIAPSIS_MAX:
      return {
        ...prevState,
        enabled: true,
        periapsis: {
          ...prevState.periapsis,
          enabled: true,
          range: {
            ...prevState.periapsis.range,
            max: action.payload
          }
        }
      }
    case ORBIT_HEIGHT__SET_PERIAPSIS_RANGE:
      return {
        ...prevState,
        enabled: true,
        periapsis: {
          ...prevState.periapsis,
          enabled: true,
          rule: RULE_BETWEEN,
          range: action.payload
        }
      }

    case ORBIT_HEIGHT__APOAPSIS_ENABLE:
      return {
        ...prevState,
        enabled: true,
        apoapsis: {
          ...prevState.apoapsis,
          enabled: true
        }
      }
    case ORBIT_HEIGHT__APOAPSIS_DISABLE:
      return {
        ...prevState,
        enabled: true,
        apoapsis: {
          ...prevState.apoapsis,
          rule: " ",
          enabled: false
        }
      }
    case ORBIT_HEIGHT__SET_APOAPSIS_RULE:
      return {
        ...prevState,
        enabled: true,
        apoapsis: {
          ...prevState.apoapsis,
          enabled: true,
          rule: action.payload
        }
      }
    case ORBIT_HEIGHT__SET_APOAPSIS_MIN:
      return {
        ...prevState,
        enabled: true,
        apoapsis: {
          ...prevState.apoapsis,
          enabled: true,
          range: {
            ...prevState.apoapsis.range,
            min: action.payload
          }
        }
      }
    case ORBIT_HEIGHT__SET_APOAPSIS_MAX:
      return {
        ...prevState,
        enabled: true,
        apoapsis: {
          ...prevState.apoapsis,
          enabled: true,
          range: {
            ...prevState.apoapsis.range,
            max: action.payload
          }
        }
      }
    case ORBIT_HEIGHT__SET_APOAPSIS_RANGE:
      return {
        ...prevState,
        enabled: true,
        apoapsis: {
          ...prevState.apoapsis,
          enabled: true,
          rule: RULE_BETWEEN,
          range: action.payload
        }
      }
    case ORBIT_HEIGHT__SET_BOTH:
      return {
        ...prevState,
        enabled: true,
        periapsis: {
          ...prevState.periapsis,
          enabled: true,
          range: action.payload.periapsis
        },
        apoapsis: {
          ...prevState.apoapsis,
          enabled: true,
          range: action.payload.apoapsis
        }
      }
    default:
      return prevState
  }
  // return prevState
}

export default orbitHeight
