import React from 'react'
import ChevronLeft from './ChevronLeft'
import ChevronRight from './ChevronRight'
import ChevronsLeft from './ChevronsLeft'
import ChevronsRight from './ChevronsRight'
import ChevronsDown from './ChevronsDown'

const Icon = React.memo(({
  icon,
  className,
  onClick
}) => {
  let iconContent = null
  switch(icon) {
    case "chevrons-down":
      iconContent = (<ChevronsDown />)
      break
    case 'chevrons-left':
      iconContent = (<ChevronsLeft />)
      break
    case 'chevrons-right':
      iconContent = (<ChevronsRight />)
      break
    case 'chevron-left':
      iconContent = (<ChevronLeft />)
      break
    case 'chevron-right':
      iconContent = (<ChevronRight />)
      break
    default:
      iconContent = null
      break
  }
  if (onClick === false) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      width="24" height="24" viewBox="0 0 24 24"
      strokeWidth="1.25" stroke="currentColor" fill="none"
      strokeLinecap="round" strokeLinejoin="round">
      {iconContent}
      </svg>
    )
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
    width="24" height="24" viewBox="0 0 24 24"
    strokeWidth="1.25" stroke="currentColor" fill="none"
    strokeLinecap="round" strokeLinejoin="round" onClick={(e) => { onClick(e) }}>
    {iconContent}
    </svg>
  )
})

Icon.defaultProps = {
  onClick: false,
  className: 'default-icon'
}

export default Icon
