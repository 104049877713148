export const vertexOrbit300 = `#version 300 es
    precision highp float;
    precision highp int;
    in vec3 position;
    in vec3 normal;
    in vec3 pathColor;
    uniform mat4 modelViewMatrix;
    uniform mat4 projectionMatrix;
    uniform mat3 normalMatrix;

    out vec3 vNormal;
    out vec3 rgb;
    void main() {
        rgb = pathColor;
        vNormal = normalize(normalMatrix * normal);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        gl_PointSize = 10.0;
    }
`;

export const vertexOrbit100 = /* glsl */`
    precision highp float;
    precision highp int;
    attribute vec3 position;
    attribute vec3 normal;
    attribute vec3 pathColor;
    uniform mat4 modelViewMatrix;
    uniform mat4 projectionMatrix;
    uniform mat3 normalMatrix;

    varying vec3 vNormal;
    varying vec3 rgb;
    void main() {
        rgb = pathColor;
        vNormal = normalize(normalMatrix * normal);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        gl_PointSize = 10.0;
    }
`;

export const fragmentOrbit300 = `#version 300 es
    precision highp float;
    precision highp int;
    uniform vec3 uColor;
    uniform float uOpacity;
    in vec3 vNormal;
    in vec3 rgb;
    out vec4 FragColor;
    void main() {
        vec3 normal = normalize(vNormal);
        vec3 color = rgb;
        float lighting = dot(normal, normalize(vec3(-0.3, 0.3, 0.3)));
        FragColor = vec4(rgb, uOpacity);
        //FragColor = vec4( color + lighting * 0.1  -0.3, 1.0);
    }
`;

export const fragmentOrbit100 = `
    precision highp float;
    precision highp int;
    uniform vec3 uColor;
    varying vec3 vNormal;
    varying vec3 rgb;
    uniform float uOpacity;
    void main() {
        vec3 normal = normalize(vNormal);
        vec3 color = rgb;
        float lighting = dot(normal, normalize(vec3(-0.3, 0.3, 0.3)));
        gl_FragColor = vec4(rgb, uOpacity);
    }
`;
