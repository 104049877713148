import React from 'react'
import './style.css'
import {
  RULE_GREATER_THAN,
  RULE_LOWER_THAN,
  RULE_BETWEEN
} from 'filterReducer/constants'

import {
  orbitHeightPeriapsisEnableAction,
  orbitHeightPeriapsisDisableAction,
  orbitHeightSetPeriapsisRuleAction,
  orbitHeightSetPeriapsisMinAction,
  orbitHeightSetPeriapsisMaxAction,
  // orbitHeightSetPeriapsisRangeAction,
  orbitHeightApoapsisEnableAction,
  orbitHeightApoapsisDisableAction,
  orbitHeightSetApoapsisRuleAction,
  orbitHeightSetApoapsisMinAction,
  orbitHeightSetApoapsisMaxAction,
  // orbitHeightSetApoapsisRangeAction
} from 'filterReducer/actions'

import {
  Button,
  Input
} from 'semantic-ui-react'

const rules = [
  RULE_GREATER_THAN,
  RULE_BETWEEN,
  RULE_LOWER_THAN
]

const OrbitHeight = React.memo(({filter, filtersDispatch}) => {
  let apoapsisMinEnabled = filter.apoapsis.rule === RULE_GREATER_THAN || filter.apoapsis.rule === RULE_BETWEEN
  let apoapsisMaxEnabled = filter.apoapsis.rule === RULE_LOWER_THAN || filter.apoapsis.rule === RULE_BETWEEN

  let periapsisMinEnabled = filter.periapsis.rule === RULE_GREATER_THAN || filter.periapsis.rule === RULE_BETWEEN
  let periapsisMaxEnabled = filter.periapsis.rule === RULE_LOWER_THAN || filter.periapsis.rule === RULE_BETWEEN

  return (
    <div className='orbit-height filter'>
      <div className='elements'>
        <p className='element-title'
          onClick={() => {
            if(filter.apoapsis.enabled) {
              filtersDispatch(orbitHeightApoapsisDisableAction())
            } else {
              filtersDispatch(orbitHeightApoapsisEnableAction())
            }
          }}
          >
          Apoapsis
        </p>
        <div style={{position: 'relative'}}>
          <Input
            size="mini"
            disabled={!apoapsisMinEnabled || !filter.apoapsis.enabled}
            style={{left: 0}}
            defaultValue={filter.apoapsis.range.min}
            onChange={(e) => {
              let value = parseFloat(e.target.value)
              if(!isNaN(value)) {
                filtersDispatch(orbitHeightSetApoapsisMinAction(value))
              }
            }}
            type="number" />
          <div className='rule-bar'>
          {rules.map((rule, i) => {
            return (
              <Button
                size='mini'
                toggle
                onClick={() => {
                  if(filter.apoapsis.rule === rule) {
                    filtersDispatch(orbitHeightApoapsisDisableAction())
                  } else {
                    filtersDispatch(orbitHeightSetApoapsisRuleAction(rule))
                  }

                }}
                active={filter.apoapsis.rule === rule && filter.enabled}
                key={i}>
                {rule}
              </Button>
            )
          })}
          </div>
          <Input
            size="mini"
            disabled={!apoapsisMaxEnabled || !filter.apoapsis.enabled}
            style={{right: 0}}
            defaultValue={filter.apoapsis.range.max}
            onChange={(e) => {
              let value = parseFloat(e.target.value)
              if(!isNaN(value)) {
                filtersDispatch(orbitHeightSetApoapsisMaxAction(value))
              }
            }}

            type="number" />
        </div>
      </div>
      <div  style={{position: 'relative', paddingTop: 30}}>
        <p className='element-title'
          onClick={() => {
            if(filter.periapsis.enabled) {
              filtersDispatch(orbitHeightPeriapsisDisableAction())
            } else {
              filtersDispatch(orbitHeightPeriapsisEnableAction())
            }
          }}
          >
          Periapsis
        </p>
        <div style={{position: 'relative'}}>
          <Input
            size="mini"
            disabled={!periapsisMinEnabled || !filter.periapsis.enabled}
            style={{left: 0}}
            defaultValue={filter.periapsis.range.min}
            onChange={(e) => {
              let value = parseFloat(e.target.value)
              if(!isNaN(value)) {
                filtersDispatch(orbitHeightSetPeriapsisMinAction(value))
              }
            }}
            type="number"
            />
          <div className='rule-bar'>
            {rules.map((rule, i) => {
              return (
                <Button
                  size="mini"
                  toggle
                  onClick={() => {
                    if(filter.periapsis.rule === rule) {
                      filtersDispatch(orbitHeightPeriapsisDisableAction())
                    } else {
                      filtersDispatch(orbitHeightSetPeriapsisRuleAction(rule))
                    }

                  }}
                  active={filter.periapsis.rule === rule && filter.enabled}
                  key={i}>
                  {rule}
                </Button>
              )
            })}
          </div>
          <Input
            size="mini"
            disabled={!periapsisMaxEnabled || !filter.periapsis.enabled}
            style={{right: 0}}
            defaultValue={filter.periapsis.range.max}
            onChange={(e) => {
              let value = parseFloat(e.target.value)
              if(!isNaN(value)) {
                filtersDispatch(orbitHeightSetPeriapsisMaxAction(value))
              }
            }}
            type="number" />
        </div>
      </div>
    </div>
  )
})

export default OrbitHeight
