import {
  CLASSIFICATION,
  CLASSIFICATION__ENABLE,
  CLASSIFICATION__DISABLE,
  CLASSIFICATION__SET
} from '../constants'

const defaultState = {
  name: "Classification",
  const: CLASSIFICATION,
  enabled: false,
  options: [
    {
      name: "Classified",
      value: "C",
      enabled: false
    },
    {
      name: "Unclassified",
      value: "U",
      enabled: false
    },
    {
      name: "Secret",
      value: "S",
      enabled: false
    }
  ]
}

const classification = (prevState = defaultState, action) => {
  if(action === undefined) {
    return prevState
  }
  switch (action.type) {
    case CLASSIFICATION__ENABLE:
      return {
        ...prevState,
        enabled: true
      }
    case CLASSIFICATION__DISABLE:
      return {
        ...prevState,
        enabled: false
      }
    case CLASSIFICATION__SET:
      return {
        ...prevState,
        enabled: true,
        options: action.payload
      }
    default:
      return prevState
  }
}

export default classification
