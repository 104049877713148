import React, { useState, useEffect } from 'react';
import SearchBox from './components/SearchBox'
import SearchSuggestions from './components/SearchSuggestions'
import AppliedFiltersSummary from './components/AppliedFiltersSummary'
import EarthView from './components/EarthView'
import Filters from './components/Filters'
import SearchResults from './components/SearchResults'
import OrbitDistanceComparison from './components/OrbitDistanceComparison'
import Footer from './components/Footer'
import CssGlobalProps from './components/CssGlobalProps'
import { logAction, logRenderSettingsReducerAction } from './analytics/analytics'
import {
  Button
} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import {
  searchAction
} from './filterReducer/actions'
import renderSettingsReducer from './renderSettingsReducer'
import {
  toggleUIVisibility,
  setTheme
} from './renderSettingsReducer/actions'


let latestQueryUpdate = new Date().getTime()

let querySent = false

const App = React.memo(({
  filters,
  filtersDispatch,
  results,
  datasets,
  sgp4WorkerInstanceDataIsSet,
  selectedSatellites,
  setSelectedSatellites,
  contractors,
  setThemeAtIndex
}) => {
  const [searchQuery, setSearchQuery] = useState(filters.search.text)
  const [currentTime, setCurrentTime] = useState(new Date().getTime())
  const [searchResultsHoveredSatellite, _setSearchResultsHoveredSatellite] = useState(false)
  const [earthViewHoveredSatellite, _setEarthViewHoveredSatellite] = useState(false)
  const [hoveringSearchResults, setHoveringSearchResults] = useState(false)
  const [compareOrbits, setCompareOrbits] = useState(false)
  const [compareAtInterval, setCompareAtInterval] = useState(false)
  const [propagatedOrbits, setPropagatedOrbits] = useState([])
  const [showFilterTab, setShowFilterTab] = useState(false)
  const [forceRerender, setForceRerender] = useState(false)
  const [draggingOrbit, setDraggingOrbit] = useState(false)
  const [renderSettings, setRenderSettings] = useState(renderSettingsReducer())

  useEffect(() => {
    if(currentTime - latestQueryUpdate < 400) {
      setTimeout(function () {
        setCurrentTime(new Date().getTime())
      }, 50);
    } else {
      if(filters.search.text !== searchQuery && querySent === false) {
        filtersDispatch(searchAction(searchQuery))
        querySent = true
      }
    }

  })
  useEffect(() => {

    if(forceRerender === true) {
      setTimeout(function () {
        setForceRerender(false)
      }, 100);
    }
  }, [forceRerender])
  function updateSearchQuery(query) {
    latestQueryUpdate = new Date().getTime()
    querySent = false
    setSearchQuery(query)
  }
  // encapsulating constant functions into functions that can
  // be copied and referenced by child components
  function setSearchResultsHoveredSatellite(satID) {
    _setSearchResultsHoveredSatellite(satID)
  }
  function setEarthViewHoveredSatellite(satID) {
    _setEarthViewHoveredSatellite(satID)
  }
  function addSatToSelectedSats(satID) {
    setSelectedSatellites(selectedSatellites.concat([satID]))
  }
  function removeSatFromSelectedSats(satID) {
    setSelectedSatellites(selectedSatellites.filter(id => id !== satID))
  }
  function resetSelectedSattelites() {
    setSelectedSatellites([])
  }
  function setThemeWrapper(theme) {
    setThemeAtIndex(theme)
    setPropagatedOrbits(propagatedOrbits.map(orbit => {
      return {
        ...orbit,
        color: theme.calculateColor(orbit.colorScale)
      }
    }))
    renderSettingsDispatch(setTheme(theme))
    setTimeout(function () {
      setForceRerender(true)
    }, 10);
  }

  function renderSettingsDispatch(action) {
    logRenderSettingsReducerAction(action)
    setRenderSettings(renderSettingsReducer(renderSettings, action))
  }

  let satActions = {
    addSatToSelectedSats: addSatToSelectedSats,
    removeSatFromSelectedSats: removeSatFromSelectedSats,
    resetSelectedSattelites: resetSelectedSattelites,
    setSearchResultsHoveredSatellite: setSearchResultsHoveredSatellite,
    setEarthViewHoveredSatellite: setEarthViewHoveredSatellite
  }

  let _results = []
  let _propagatedOrbits = []
  if(forceRerender === false) {
    _results = results
    _propagatedOrbits = propagatedOrbits
  }

  let selectedSats = results.filter(p => selectedSatellites.includes(p.id))

  let appClass = "App"
  if(draggingOrbit) {
    appClass += ' dragging-orbit'
  }
  return (
    <div className={appClass}>
      <div className='hide-ui-button-container'>
      <Button onClick={() => renderSettingsDispatch(toggleUIVisibility())} size="tiny">
        {renderSettings.hideUI ? "show": "hide"} UI
      </Button>
      </div>
      <div id="earth-view-container">
      {true ? (
        <EarthView
          results={_results}
          sgp4WorkerInstanceDataIsSet={sgp4WorkerInstanceDataIsSet}
          hoveringSearchResults={hoveringSearchResults}
          searchResultsHoveredSatellite={searchResultsHoveredSatellite}
          earthViewHoveredSatellite={earthViewHoveredSatellite}
          satActions={satActions}
          selectedSatellites={selectedSatellites}
          compareOrbits={compareOrbits}
          compareAtInterval={compareAtInterval}
          propagatedOrbits={_propagatedOrbits}
          hideUI={renderSettings.hideUI}
          theme={renderSettings.theme}
          setTheme={setThemeWrapper}
          draggingOrbit={draggingOrbit}
          setDraggingOrbit={setDraggingOrbit}
          filtersDispatch={filtersDispatch}
          renderSettings={renderSettings}
          renderSettingsDispatch={renderSettingsDispatch}
          />
      ) : null}
      </div>
      <div className='scroll-overlay' style={{opacity: renderSettings.hideUI ? 0 : 1}}>
        <div className='search-controls'>

          <div className={showFilterTab ? "filter-box showing" : "filter-box hidden"}>
            <Filters
              filters={filters}
              filtersDispatch={filtersDispatch}
              showFilterTab={showFilterTab}
              setShowFilterTab={setShowFilterTab}
              contractors={contractors}
              />
          </div>
          <div className={showFilterTab ? 'search filter-showing' : "search filter-hidden"}>
            <header>
              <h1>SPACE-SEARCH.IO</h1>
              <p className='about-link'><a target="_blank" rel="noopener noreferrer" href='https://dialectic.design/project/space-search-io'>About this website</a></p>
            </header>
            <div className='earth-view-spacer'>
            </div>
            <OrbitDistanceComparison
              compareOrbits={compareOrbits}
              satellites={selectedSats}
              propagatedOrbits={propagatedOrbits}
              setPropagatedOrbits={setPropagatedOrbits}
              draggingOrbit={draggingOrbit}
              theme={renderSettings.theme}
              compareAtInterval={compareAtInterval}
              setCompareAtInterval={setCompareAtInterval}
              orbitComparisonRenderType={renderSettings.orbitComparisonRenderType}
              renderSettingsDispatch={renderSettingsDispatch}
              />
            <div className='filter-controls'>
            <AppliedFiltersSummary filters={filters} filtersDispatch={filtersDispatch}  />
            <Button
              size="small"
              toggle
              active={showFilterTab}
              onClick={() => setShowFilterTab(!showFilterTab)} >
            {showFilterTab ? "Hide filters" : "Show filters"}
            </Button>
            {selectedSatellites.length > 1 ? (
              <Button toggle active={compareOrbits} size="small"
                onClick={() => {
                  logAction({type: "compareOrbitsClicked", payload: selectedSats.map(p => p.name)})
                  setCompareOrbits(!compareOrbits)
                }} >
                {compareOrbits ? "Comparing " : "Compare "}orbit distances
              </Button>
            ) : null}
            </div>
            <SearchBox
              setSearch={(query) => { updateSearchQuery(query) }}
              initialSearch={filters.search.text}
              />
            {results.length > 0 ? (
              <div className='output'>
                {searchQuery && false ? (
                  <SearchSuggestions
                    searchQuery={searchQuery}
                    categories={datasets.categories} />
                ) : null}
                <SearchResults
                  searchResultsHoveredSatellite={searchResultsHoveredSatellite}
                  earthViewHoveredSatellite={earthViewHoveredSatellite}
                  results={_results}
                  satActions={satActions}
                  selectedSatellites={selectedSatellites}
                  setHoveringSearchResults={setHoveringSearchResults}
                  theme={renderSettings.theme}
                  />
              </div>
            ) : null}
          </div>
        </div>
        <Footer />
      </div>
      <CssGlobalProps theme={renderSettings.theme} />
    </div>
  );
})



export default App;
