import {
  Vec3
} from 'ogl'

import CustomPolyline from '../../components/CustomPolylineCompareAt'

import {
  gl
} from '../earthViewRenderer'

function createCompareAtPath(compareAtInterval, propagatedOrbits, renderTools) {
  const p1 = propagatedOrbits[0].propagatedPosition[Math.round(propagatedOrbits[0].propagatedPosition.length * compareAtInterval)]
  const p2 = propagatedOrbits[1].propagatedPosition[Math.round(propagatedOrbits[1].propagatedPosition.length * compareAtInterval)]
  const points = [
    new Vec3(p1.pos.x/1000, p1.pos.z/1000, p1.pos.y /-1000),
    new Vec3(p2.pos.x/1000, p2.pos.z/1000, p2.pos.y /-1000)
  ]
  let polyline = new CustomPolyline(gl, {
    points: points,
    uniforms: {
      uColor:{value: renderTools.color},
      uThickness: {value: 10},
      uOpacity: {value: 1}
    }
  })
  polyline.mesh.type = 'compare-at-path'
  polyline.mesh.setParent(renderTools.scene)
}

export default createCompareAtPath
