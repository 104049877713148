import React, { useState, useEffect } from 'react'
import { themes } from 'constants/themes'
import { Button } from 'semantic-ui-react'


const highlightedThemeKeys = [
  'main',
  'inverse',
  'fill',
  'fill_hover',
  'fill_dark',
  'fill_dark_hover',
  'highlight',
  'highlight_hover',
  "secondaryHighlightCSS",
  "background_25",
  "background_50",
  "background_75",
  "background_90",
  "background"
]

const ThemeSelector = React.memo(({
  theme,
  setTheme,
  width
}) => {
  const [showingOptions, setShowingOptions] = useState(false)
  const [selectedATheme, setSelectedATheme] = useState(false)
  const [lastSelection, setLastSelection] = useState(new Date().getTime())
  const [currentTime, setCurrentTime] = useState(new Date().getTime())
  let optionsContainerClass = `theme-options`
  if(showingOptions || width < 796) {
    optionsContainerClass += " showing"
  }
  useEffect(() => {
    if(selectedATheme && showingOptions && currentTime - lastSelection < 3000) {
      setTimeout(function () {
        setCurrentTime(new Date().getTime())
      }, 1000);
    } else {
      if(selectedATheme) {
        setShowingOptions(false)
        setSelectedATheme(false)
      }

    }
  }, [
    selectedATheme,
    showingOptions,
    lastSelection,
    currentTime
  ])

  return (
    <div className='theme-selector'>
    {width >= 796 ? (
      <Button onClick={() => {
        setShowingOptions(!showingOptions)
      }}>
      Color theme
      </Button>
    ) : null}

      <div className={optionsContainerClass}>
      {themes.map((_theme, i) => {
        return (
          <div key={i}  className='theme'>
          <button
            onClick={() => {
              setTheme(_theme)
              setLastSelection(new Date().getTime())
              setSelectedATheme(true)
            }}
            className='theme-select-button'
            >

          {highlightedThemeKeys.map(key => {
            return (
              <div style={{background: _theme[key]}} key={key} className='theme-color-box'>
              </div>
            )
          })}
          </button>
          </div>
        )
      })}
      </div>
    </div>
  )
})

export default ThemeSelector
