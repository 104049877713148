import React, { useState } from 'react'
import {
  Input,
  Button
} from 'semantic-ui-react'
import './style.css'

const suggestions = [
  "Crew Dragon",
  "Flock",
  "Starlink",
  "Ariane",
  "Atlas",
  "GPS",
  "Galileo",
  "FALCON 9",
  "OneWeb",
  "Beidou",
  "cospar 19",
  'cospar 98067A'
]

const moreSuggestions = [
  "glonass",
  "Aerocube",
  "sl-12",
  "Lemur",
  "Chinasat",
  "SES",
  "Yaogan",
  "titan 3c",
  "Eutelsat"
]

let firstRender = true

const SearchBox = React.memo(({
  setSearch,
  onSearch,
  initialSearch
}) => {
  const [searchQuery, setSearchQuery] = useState(firstRender ? initialSearch : "")
  const [suggestionsVisible, hideSuggestions] = useState(true)
  const [showMoreSuggestions, setShowMoreSuggestions] = useState(false)
  firstRender = false
  function checkKey(key) {
    if(key === 'Enter') {
      onSearch(searchQuery)
    }
  }
  return (
    <>
    <div
      className='dd-search-box search-box'
      style={{display: 'flex', paddingTop: 10}}>
      <Input
       onKeyPress={(e) => { checkKey(e.key) }}
       onChange={(e) => {
         setSearchQuery(e.target.value)
         setSearch(e.target.value)
       }}
       value={searchQuery}
       style={{width: 'calc(30% - 50px)', minWidth: 200, marginRight: 20, pointerEvents: 'auto'}}
       icon='search'
       placeholder='Search...' />
    </div>
    <div className='search-suggestions'>
      {suggestionsVisible ? (
        <div className='suggestions-text'>
          <p>Suggestions:</p>
          <div className='suggestion-buttons'>
          {suggestions.map((sugg, i) => {
            return (
              <Button size="tiny"
                key={i}
                className="search-suggestion"
                onClick={() => {
                  setSearchQuery(sugg)
                  setSearch(sugg)
                }} >
              {sugg}
              </Button>
            )
          })}
          </div>
          {showMoreSuggestions ? (
            <div className='suggestion-buttons'>
            {moreSuggestions.map((suggestion, i) => {
              return (
                <Button size="tiny"
                  key={i}
                  className="search-suggestion"
                  onClick={() => {
                    setSearchQuery(suggestion)
                    setSearch(suggestion)
                  }}>
                {suggestion}
                </Button>
              )
            })}
            </div>
          ) : null}
        </div>
      ) : null}
      {suggestionsVisible ? (
        <Button size="small"
          onClick={() => {setShowMoreSuggestions(!showMoreSuggestions)}}>
          {showMoreSuggestions ? "Hide" : "Show"} more
        </Button>
      ) : null}

      <Button size="small"
        onClick={() => {hideSuggestions(!suggestionsVisible)}}>
        {suggestionsVisible ? "Hide" : "Show"} suggestions
      </Button>
    </div>
    </>
  )
})

SearchBox.defaultProps = {
  onSearch: (searchTerm) => { console.log('implement serch for: '+searchTerm); }
}

export default SearchBox
