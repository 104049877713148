// eslint-disable-next-line import/no-webpack-loader-syntax
import extractCategoriesWorker from 'workerize-loader!../workers/extractCategoriesWorker'
// eslint-disable-next-line import/no-webpack-loader-syntax
import extractFunctionsWorker from 'workerize-loader!../workers/extractFunctionsWorker'

export const etls = [
  {
    dataset: "UCSsatDb",
    name: "categories",
    worker: extractCategoriesWorker
  },
  {
    dataset: 'UCSsatDb',
    name: 'functions',
    worker: extractFunctionsWorker
  }
]

export const processStates = {
  INITIALISED: "INITIALISED",
  PROCESSING: "PROCESSING",
  READY: "READY"
}
