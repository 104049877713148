import React, { useState } from 'react'
import {
  mouseDistanceThreshold
} from '../../constants'
import Icon from 'components/Icon'
import SatGraphs from './SatGraphs'
import {
  setCameraLookAtPoint
} from 'renderSettingsReducer/actions'
import { simpleLogAction } from 'analytics/analytics'

const darkening = 0.9

const SatLabelPositionContainer = React.memo(({
  sat,
  highlight,
  hoveringSearchResults,
  selected,
  isFirst,
  width,
  height,
  satActions,
  draggingOrbit,
  renderSettingsDispatch,
  filtersDispatch
}) => {

  const closest = isFirst && sat.mouseDistance < mouseDistanceThreshold && !hoveringSearchResults && !draggingOrbit
  const posX = width * 0.5 + (sat.vec2DLoc[0] * width * 0.5)
  const posY = height * 0.5 + (sat.vec2DLoc[1] * -height * 0.5)
  let positionClass = 'satellite-label-position'
  let positionStyle = {
    position: 'absolute',
    top: posY,
    left: posX,
    opacity: sat.behindEarth ? 0.25 : 1
  }
  let showHighlight = false
  if(closest || selected) {
    showHighlight = true
    positionClass += ' closest'
  }
  if(selected) {
    positionClass += ' selected'
  }
  const satParams = {
    apoapsis: sat.sat.apoapsis,
    periapsis: sat.sat.periapsis,
    revolution: sat.sat.tle.revolution,
    inclination: sat.sat.tle.inclination,
    ascension: sat.sat.tle.ascension
  }
  function lookAtSat() {
    simpleLogAction('Overlay', 'SatLabel', 'sat-name', sat.sat.name)
    renderSettingsDispatch(setCameraLookAtPoint(sat.vecPos))
  }
  return (
    <div style={positionStyle} className={positionClass}>
      <SatLabel
        closest={closest}
        showHighlight={showHighlight}
        color={sat.sat.color}
        selected={selected}
        name={sat.sat.name}
        satActions={satActions}
        id={sat.sat.id}
        behindEarth={sat.behindEarth}
        satParams={satParams}
        lookAtSat={lookAtSat}
        filtersDispatch={filtersDispatch}
        />
    </div>
  )
})



const SatLabel = React.memo(({
  closest,
  color,
  showHighlight,
  selected,
  name,
  satActions,
  id,
  behindEarth,
  lookAtSat,
  filtersDispatch,
  satParams
}) => {
  const [hoveringLabel, setHoveringLabel] = useState(false)
  const [expand, setExpand] = useState(false)
  let hovering = closest || hoveringLabel
  let bgHoveredOpacity = hovering ? 0.5 : 0.9
  if(selected && hovering) {
    bgHoveredOpacity = 0.7
  }
  let labelClass = 'satellite-label'

  if (closest || selected) {
    labelClass += ' closest'
  }
  if(selected) {
    labelClass += ' selected'
  }
  let containerStyle = {}
  if(showHighlight) {
    labelClass += ' hovered'
    containerStyle.background = `rgba(
      ${color.r * 255 * darkening},
      ${color.g * 255 * darkening},
      ${color.b * 255 * darkening}, ${bgHoveredOpacity})`
  }

  return (
    <>
      {showHighlight ? (
        <div style={{
          border:`2px solid rgba(${color.r * 255 * darkening},
            ${color.g * 255 * darkening},
            ${color.b * 255 * darkening}, ${selected ? 0.5 : 0.1})`,
          background: `rgba(
            ${color.r * 255 * darkening},
            ${color.g * 255 * darkening},
            ${color.b * 255 * darkening}, ${selected ? 0.1 : 0.0})`,
          opacity: 1
        }} className='circle'></div>
      ) : null}

      <div style={containerStyle} className='label-container'>
        <div className='row'>
          <p
            onMouseEnter={() => setHoveringLabel(true)}
            onMouseLeave={() => setHoveringLabel(false)}
            className={labelClass}
            onClick={() => {
              if(hoveringLabel && selected) {
                  setExpand(false)
                  satActions.removeSatFromSelectedSats(id)
              }
            }}
            >
            {name}
            {behindEarth ? (
              <span className='behind-earth-label'>
              <br />
              behind earth
              </span>
            ) : null}
          </p>
          {selected ? (
            <div
              className='expand-container' style={{transform:expand ? 'scale(1, -1)' : 'scale(1, 1)'}}>
            <Icon
              icon="chevrons-down"
              className='expand-button'
              onClick={() => { setExpand(!expand) }}
              />
            </div>
          ) : null}
        </div>
        <div className='expand-information' style={{height: expand ? 97 : 0}}>
          {expand && showHighlight ? (
            <>
              <SatGraphs
                color={color}
                satParams={satParams}
                lookAtSat={lookAtSat}
                filtersDispatch={filtersDispatch}
                />
            </>
          ) : null}
        </div>
      </div>
    </>
  )
})

export default SatLabelPositionContainer
