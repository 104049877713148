import _ from 'lodash'
import { Vec3 } from 'ogl'

function updateSatPosition(satellitesByID, propagatedSatellite) {
  if (_.has(satellitesByID, propagatedSatellite.id)) {
    satellitesByID[propagatedSatellite.id].pointPosTime = propagatedSatellite.positionTime
    satellitesByID[propagatedSatellite.id].pointPos = propagatedSatellite.position
    satellitesByID[propagatedSatellite.id].vecPos = new Vec3(
      _.get(propagatedSatellite, 'position.x',0) / 1000,
      _.get(propagatedSatellite, 'position.z',0) / 1000,
      _.get(propagatedSatellite, 'position.y',0) /-1000
    )
    satellitesByID[propagatedSatellite.id].pointPosAvailable = true
  }
}

export default updateSatPosition
