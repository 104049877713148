import React from 'react';
import ReactDOM from 'react-dom';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

import App from './App';
import { datafiles, loadingStates } from './constants/datafiles'
import { etls, processStates } from './constants/etls'
import filterReducer from './filterReducer'
import _ from 'lodash'
// eslint-disable-next-line import/no-webpack-loader-syntax
import searchWorker from 'workerize-loader!./workers/searchWorker'
import { sgp4WorkerInstance } from './workers/workers'
import ReactGA from 'react-ga'
import { logAction, simpleLogAction } from './analytics/analytics'
import {themes} from 'constants/themes'
import './index.css';
import getParams from 'utils/getParams'

ReactGA.initialize('UA-92339423-5', {
  debug: false,
  titleCase: false,
  gaOptions: {
    siteSpeedSampleRate: 100
  }
});

let searchWorkerInstance = new searchWorker()

let results = []
function setSelectedSatellites(newSats) {
  const satNames = newSats.map(satId => {
    let sat = _.find(results, (p) => p.id === satId)
    if(sat === undefined) { return "sat not found" }
    else { return sat.name }
  })
  simpleLogAction("Selected sattelites", "set selection", satNames)
  filters.selectedSatellites = newSats
  render()
}
let workerHasData = false

let url = 'http://localhost:3000/datasets'

const urlParams = getParams(window.location.href)
let iframeMode = _.get(urlParams, 'iframeMode', false)

if(window.location.href.includes('space-search.io')) {
  url = 'https://space-search.io/datasets'
  if(!window.location.href.includes('?goal=conf-demo-page')) {
    if(!iframeMode) {
      ReactGA.pageview(window.location.href)
    }
  }
} else if(window.location.href.includes('192.168')) {
  url = window.location.href + '/datasets'
}

let datasets = {}

let filters = filterReducer()

export let contractors = []

datafiles.forEach(file => {
  file.loadingState = loadingStates.LOADING
})

etls.forEach(etl => {
  etl.processState = processStates.INITIALISED
})

datafiles.forEach(file => {
  fetch(url + file.filepath).then(resp => resp.text())
    .then(text => {
      if(text.includes('<!DOCTYPE html>') || text === undefined) {
        file.loadingState = loadingStates.UNAVAILABLE
      } else {
        let workerInstance = file.worker()
        workerInstance.processData(text)
          .then(data => {
            datasets[file.name] = data
            setTimeout(function () {
              file.loadingState = loadingStates.AVAILABLE
              mergeData()
            }, 10);

          })
      }

    })
})



function mergeData() {
  if(loadingStatus()) {
    let tle_objects = datasets.spaceObjects
    let ucs_by_tleCOSPAR = _.groupBy(datasets.UCSsatDb, 'tleCospar')
    datasets.spaceObjects = tle_objects.map(obj => {
      let meta = _.get(ucs_by_tleCOSPAR, obj.cospar_id_file, false)[0]
      return {
        ...obj,
        meta: meta,
        dateOfLaunch: meta ? meta.dateOfLaunch : "-1",
        allMatches: _.get(ucs_by_tleCOSPAR, obj.cospar_id_file, false)
      }
    })
    datasets.spaceObjects = _.sortBy(datasets.spaceObjects, 'dateOfLaunch').reverse()
    etls.forEach(etl => {
      etl.processState = processStates.PROCESSING
      let workerInstance = etl.worker()
      workerInstance.processData(datasets[etl.dataset]).then(data => {

        datasets[etl.name] = data
        setTimeout(function () {
          etl.processState = processStates.READY
          render()
        }, 100);

      })
    })
    setTimeout(function () {
      filtersDispatch({type: "INIT"})
    }, 100);

  }
}

function filtersDispatch(action) {
  if(action.type !== "INIT") {
    logAction(action)
  }
  filters = filterReducer(filters, action)
  searchWorkerInstance.search(filters).then(searchResults => {
    results = searchResults
    render()
  })
}


let theme = themes[0]

function setThemeAtIndex(newTheme) {
  theme = newTheme
  render()
}

function setDataOnSearchWorker() {
  searchWorkerInstance.setData(datasets).then(message => {
    if(message.success) {
      workerHasData = true
      searchWorkerInstance.search(filters).then(searchResults => {
        results = searchResults
        setTimeout(function () {
          render()
          setTimeout(function () {
            setDataOnSGPWorker()
          }, 10);
        }, 10);

      })
    }
  })

}

var sgp4WorkerInstanceDataIsSet = false

function setDataOnSGPWorker() {
  sgp4WorkerInstance.setData(datasets.spaceObjects.map(p => {
    return {
      name: p.celestrak_name,
      eccentricity: p.eccentricity,
      id: p.id,
      motion: p.motion,
      twoLinePair: p.twoLinePair
    }
  })).then(message => {
    sgp4WorkerInstanceDataIsSet = true
    render()
  })
}


function render() {
  let processState = processStatus()
  if(processState) {
    if(contractors.length === 0) {
      let contractorsDataset = _.find(datasets.categories, p => p.label === 'Contractor')
      if(contractorsDataset !== undefined) {
        contractors = contractorsDataset.tags.map(tag => tag.toLowerCase())
      }
    }
    if(workerHasData === false) {
      if(searchWorkerInstance.setData !== undefined) {}
      if(_.isFunction(searchWorkerInstance.setData)) {
          setDataOnSearchWorker()
      }

    }
  }
  // add color theme
  const _results = results.map(sat => {
    return {
      ...sat,
      color:theme.calculateColor(sat.colorScale)
    }
  })
  ReactDOM.render(
    <App
      dataLoaded={processState}
      filters={filters}
      filtersDispatch={filtersDispatch}
      results={_results}
      datasets={datasets}
      sgp4WorkerInstanceDataIsSet={sgp4WorkerInstanceDataIsSet}
      selectedSatellites={filters.selectedSatellites}
      setSelectedSatellites={setSelectedSatellites}
      contractors={contractors}
      setThemeAtIndex={setThemeAtIndex}
      />, document.getElementById('root'));

}


function loadingStatus() {
  return datafiles.every(p => p.loadingState === loadingStates.AVAILABLE)
}

function processStatus() {
  return etls.every(p => p.processState === processStates.READY)
}

render()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
