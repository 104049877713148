import React, { useState } from 'react'

import {
  setContractorsAction
} from '../../../filterReducer/actions'

import {
  Input,
  Button
} from 'semantic-ui-react'

import './index.css'

const Contractor = React.memo(({filter, filtersDispatch, contractors}) => {
  const [searchQuery, setSearchQuery] = useState("")
  return (
    <div className='filter contractor-filter'>
    <Input
      style={{pointerEvents: 'auto', width: '100%'}}
      placeholder='Search contractors...'
      onChange={(e) => {
        setSearchQuery(e.target.value)
      }}
      type="text"
      />
    <div>
    <Button style={{width: '100%', marginTop: 5}} size="small" onClick={() => {
      filtersDispatch(setContractorsAction([]))
    }}>Reset selection</Button>
    </div>
    {filter.contractors.length > 0 ? (
      <div className='selected-contractors'>
      <p>Selected contractors</p>
      {filter.contractors.map(contractor => {
        return (
          <Button
            size="mini"
            toggle
            key={contractor}
            onClick={() => {
              filtersDispatch(setContractorsAction(filter.contractors.filter(
                c => { return c !== contractor }
              )))
            }}
            >
            {contractor}
          </Button>
        )
      })}
      </div>
    ) : null}
    {searchQuery.length > 0 ? (
      <div className='contractor-search-results'>
      <Button className='hide-results' onClick={() => { setSearchQuery("") }} >hide</Button>
      {contractors.filter(contractor => contractor.includes(searchQuery.toLowerCase()))
          .map(contractor => {
            let contractorSelected = filter.contractors.includes(contractor)
        return (
          <Button
            size="mini"
            toggle
            key={contractor}
            active={contractorSelected}
            onClick={() => {
              if(contractorSelected) {
                filtersDispatch(setContractorsAction(filter.contractors.filter(
                  c => { return c !== contractor }
                )))
              } else {
                filtersDispatch(setContractorsAction(filter.contractors.slice().concat([contractor])))
              }
            }}
            >
            {contractor}
          </Button>
        )
      })}
      </div>
    ) : null}
    </div>
  )
})

export default Contractor
