import React, { useState } from "react";
import {
  Input,
  Button
} from 'semantic-ui-react'

// a basic form
const SimpleForm = ({ status, message, className, style, onSubmitted }) => {
  const [inputValue, setInputValue] = useState("")
  const submit = () =>
    inputValue &&
    inputValue.indexOf("@") > -1 &&
    onSubmitted({
      EMAIL: inputValue
    });

  return (
    <div className={className} style={style}>
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <Input
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        size="small"
        type="email"
        placeholder="Your email"
      />
      <Button size="medium" onClick={submit}>Submit</Button>
    </div>
  );
};

export default SimpleForm;
