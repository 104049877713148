import {
  SET_LINE_WIDTH,
  SET_CAMERA_ZOOM_LEVEL,
  SET_CAMERA_LOOK_AT_POINT,
  SET_EARTH_TEXTURE_OPACITY,
  SET_EARTH_WIREFRAME_OPACITY,
  SET_ORBIT_PATH_OPACITY,
  SET_ANIMATION_MULTIPLIER,
  TOGGLE_UI_VISIBILITY,
  SET_THEME,
  SET_ORBIT_COMPARISON_RENDER_TYPE
} from './constants'

export const setLineWidth = (lineWidth) => {
  return {
    type: SET_LINE_WIDTH,
    payload: lineWidth
  }
}

export const setCameraZoomLevel = (cameraZoomLevel) => {
  return {
    type: SET_CAMERA_ZOOM_LEVEL,
    payload: cameraZoomLevel
  }
}

export const setCameraLookAtPoint = (point) => {
  return {
    type: SET_CAMERA_LOOK_AT_POINT,
    payload: point
  }
}

export const setEarthTextureOpacity = (opacity) => {
  return {
    type: SET_EARTH_TEXTURE_OPACITY,
    payload: opacity
  }
}

export const setEarthWireFrameOpacity = (opacity) => {
  return {
    type: SET_EARTH_WIREFRAME_OPACITY,
    payload: opacity
  }
}

export const setOrbitPathOpacity = (opacity) => {
  return {
    type: SET_ORBIT_PATH_OPACITY,
    payload: opacity
  }
}

export const setAnimationMultiplier = (multiplier) => {
  return {
    type: SET_ANIMATION_MULTIPLIER,
    payload: multiplier
  }
}

export const toggleUIVisibility = () => {
  return {
    type: TOGGLE_UI_VISIBILITY
  }
}

export const setTheme = (theme) => {
  return {
    type: SET_THEME,
    payload: theme
  }
}

export const setOrbitComparisonRenderType = (renderType) => {
  return {
    type: SET_ORBIT_COMPARISON_RENDER_TYPE,
    payload: renderType
  }
}
