import {
  CONTRACTOR,
  CONTRACTOR__ENABLE,
  CONTRACTOR__DISABLE,
  CONTRACTOR__SET
} from '../constants'

const defaultState = {
  name: "Contractor",
  const: CONTRACTOR,
  enabled: false,
  contractors: []
}

const contractor = (prevState = defaultState, action) => {
  if(action === undefined) {
    return prevState
  }
  switch (action.type) {
    case CONTRACTOR__ENABLE:
      return {
        ...prevState,
        enabled: true
      }
    case CONTRACTOR__DISABLE:
      return {
        ...prevState,
        enabled: false
      }
    case CONTRACTOR__SET:
      return {
        ...prevState,
        enabled: true,
        contractors: action.payload
      }
    default:
      return prevState
  }
}

export default contractor
