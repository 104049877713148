import {
  MASS,
  MASS__ENABLE,
  MASS__DISABLE,
  MASS__SET_RULE,
  MASS__SET_MIN,
  MASS__SET_MAX,
  MASS__SET_RANGE,

  RULE_BETWEEN
} from '../constants'

const defaultState = {
  name: "Mass",
  enabled: false,
  const: MASS,
  rule: RULE_BETWEEN,
  range: {
    min: 0,
    max: 100000
  }
}

const mass = (prevState = defaultState, action) => {
  if(action === undefined) {
    return prevState
  }
  switch (action.type) {
    case MASS__ENABLE:
      return {
        ...prevState,
        enabled: true
      }
    case MASS__DISABLE:
      return {
        ...prevState,
        enabled: false
      }
    case MASS__SET_RULE:
      return {
        ...prevState,
        enabled: true,
        rule: action.payload
      }
    case MASS__SET_MIN:
      return {
        ...prevState,
        enabled: true,
        range: {
          ...prevState.range,
          min: action.payload
        }
      }
    case MASS__SET_MAX:
      return {
        ...prevState,
        enabled: true,
        range: {
          ...prevState.range,
          max: action.payload
        }
      }
    case MASS__SET_RANGE:
      return {
        ...prevState,
        enabled: true,
        rule: RULE_BETWEEN,
        range: action.payload
      }
    default:
      return prevState
  }
}

export default mass
