import _ from 'lodash'

function updateSatPropagation(satellites, propagatedSatellite) {
  let sat = _.find(satellites, p => p.id === propagatedSatellite.id)
  if(sat !== undefined) {
    sat.positionTime = propagatedSatellite.positionTime
    sat.position = propagatedSatellite.position
    sat.propagatedPosition = propagatedSatellite.propagatedPosition
    sat.propagatedOrbitCount = propagatedSatellite.propagatedOrbitCount
    if(sat.position === undefined) {
      sat.positionAvailable = 'unavailable'
    } else {
      sat.positionAvailable = true
    }
  }
}

export default updateSatPropagation
