import {
  ORBIT_TYPE,
  ORBIT_TYPE__DISABLE,
  ORBIT_TYPE__ENABLE,
  ORBIT_TYPE__SET
} from '../constants'

const defaultState = {
  name: "Orbit",
  enabled: false,
  const: ORBIT_TYPE,
  types: [
    {
      label: "LEO",
      fullName: "Low Earth Orbit",
      icon: '/',
      range: [0, 2000],
      enabled: false
    },
    {
      label: "MEO",
      fullName: "Medium Earth Orbit",
      icon: '/',
      range: [2000, 36000],
      enabled: false
    },
    {
      label: "GEO",
      fullName: "Geostationary Earth Orbit",
      icon: '/',
      range: [36000, 37000],
      enabled: false
    },
    {
      label: "HEO",
      fullName: "High Earth Orbit",
      icon: '/',
      range: [36000, 37000],
      enabled: false
    }
  ]
}

const orbitType = (prevState = defaultState, action) => {
  if(action === undefined) {
    return prevState
  }
  switch (action.type) {
    case ORBIT_TYPE__ENABLE:
      return {
        ...prevState,
        enabled: true
      }
    case ORBIT_TYPE__DISABLE:
      return {
        ...prevState,
        enabled: false
      }
    case ORBIT_TYPE__SET:
      console.log(action.payload);
      if(action.payload.map(p => p.enabled).some(p => p)) {
        return {
          ...prevState,
          enabled: true,
          types: action.payload
        }
      } else {
        return {
          ...prevState,
          enabled: false,
          types: action.payload
        }
      }

    default:
      return prevState
  }
}

export default orbitType
