import React from 'react'
import { Button } from 'semantic-ui-react'
import Icon from 'components/Icon'
import './animation-controls.css'
import {
  setAnimationMultiplier
} from 'renderSettingsReducer/actions'


const AnimControls = React.memo(({
  animationMultiplier,
  renderSettingsDispatch
}) => {
  if(true) {
    return (
      <div className='animation-controls'>
        <p className='label'>Animation</p>
        <div className='button-container'>
        <Button
          className='play-pause-button'
          onClick={() => {
            if(animationMultiplier === false) {
              renderSettingsDispatch(setAnimationMultiplier(1))
            } else {
              renderSettingsDispatch(setAnimationMultiplier(false))
            }
          }}
          >
          {animationMultiplier === false ? "play" : "pause"}
        </Button>
        </div>
        <p className='label'>Speed</p>
        <div className='speed-controls'>
          <Button onClick={() => { renderSettingsDispatch(setAnimationMultiplier(-3)) }} size="tiny">
            <Icon icon="chevrons-left" className='speed-control' />
          </Button>
          <Button onClick={() => { renderSettingsDispatch(setAnimationMultiplier(-1)) }} size="tiny">
            <Icon icon="chevron-left" className='speed-control' />
          </Button>
          <Button onClick={() => { renderSettingsDispatch(setAnimationMultiplier(1)) }} size="tiny">
            <Icon icon="chevron-right" className='speed-control' />
          </Button>
          <Button onClick={() => { renderSettingsDispatch(setAnimationMultiplier(3)) }} size="tiny">
            <Icon icon="chevrons-right" className='speed-control' />
          </Button>
        </div>
      </div>
    )
  } else {
    return null
  }
})

export default AnimControls
