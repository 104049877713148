import React from 'react'
import _ from 'lodash'
import {
  launchYearSetRuleAction,
  launchYearSetYearAction,
  launchYearSetMinAction,
  launchYearSetMaxAction,
  launchYearSetRangeAction
} from '../../../filterReducer/actions'

import {
  RULE_EQUAL,
  RULE_GREATER_THAN,
  RULE_LOWER_THAN,
  RULE_BETWEEN,
  RULE_NONE_SET
} from '../../../filterReducer/constants'

import {
  Button,
  Input
} from 'semantic-ui-react'

const rules = [
  RULE_EQUAL,
  RULE_GREATER_THAN,
  RULE_BETWEEN,
  RULE_LOWER_THAN
]

const LaunchYear = React.memo(({filter, filtersDispatch}) => {
  return (
    <div className='filter'>
      <div>
        <div className='rule-bar'>
        {rules.map(rule => {
          return (
            <Button
              size='mini'
              toggle
              onClick={() => {
                if(filter.rule === rule) {
                  filtersDispatch(launchYearSetRuleAction(RULE_NONE_SET))
                } else {
                  filtersDispatch(launchYearSetRuleAction(rule))
                }

              }}
              active={filter.rule === rule && filter.enabled}
              key={rule}
              >
              {rule}
            </Button>
          )
        })}
        </div>
        <Input
          size="mini"
          value={filter.range.min}
          onChange={(e) => {
            let value = parseFloat(e.target.value)
            if(!isNaN(value)) {
              filtersDispatch(launchYearSetMinAction(value))
            }
          }}
          style={{left: 0}}
          disabled={!filter.enabled
            || filter.rule === RULE_LOWER_THAN
            || filter.rule === RULE_NONE_SET}
          type="number" />
        <Input
          size="mini"
          value={filter.range.max}
          disabled={!filter.enabled
              || filter.rule === RULE_GREATER_THAN
              || filter.rule === RULE_NONE_SET
              || filter.rule === RULE_EQUAL}
          onChange={(e) => {
            let value = parseFloat(e.target.value)
            if(!isNaN(value)) {
              filtersDispatch(launchYearSetMaxAction(value))
            }
          }}
          style={{right: 0}}
          type="number" />
      </div>
      <div className='filter-suggestions'>
        <p style={{marginBottom: 0}}>Set to:</p>
        {_.range(6).map(val => {
          return (
            <Button
              size="mini"
              key={val}
              onClick={() => {
                filtersDispatch(launchYearSetYearAction(2020-val))
              }}
              >
              {2020 - val}
            </Button>
          )
        })}

      </div>
      <div className='filter-suggestions'>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(launchYearSetRangeAction({
              min: 1990,
              max: 2000
            }))
          }}
          >
          1990-2000
        </Button>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(launchYearSetRangeAction({
              min: 2000,
              max: 2010
            }))
          }}
          >
          2000-2010
        </Button>
        <Button
          size="mini"
          onClick={() => {
            filtersDispatch(launchYearSetRangeAction({
              min: 2010,
              max: 2020
            }))
          }}
          >
          2010-2020
        </Button>
      </div>
    </div>
  )
})

export default LaunchYear
