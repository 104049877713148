import React from 'react'
import moment from 'moment'
import './timeline.css'

const Timeline = React.memo(({
  currentTime,
  timeOffset,
  setTimeOffset
}) => {
  const currentTimeDate = moment(currentTime)
  // const offsetTimeDate = moment(currentTime + timeOffset)
  // const offsetInSecs = offsetTimeDate.diff(currentTimeDate, 'seconds')
  return (
      <p className='timeline'>Satellite positions for {' '}
        {currentTimeDate.format("YY-MM-DD:HH:mm:ss ")}
        </p>
  )
})

export default Timeline

/**

 + offset: {offsetInSecs} seconds


<div className='time-controls'>
  <div>
    <Button onClick={() => { setTimeOffset(timeOffset + 1000) }}>+ 1 second</Button>
    <Button onClick={() => { setTimeOffset(timeOffset + 1000 * 10) }}>+10 seconds</Button>
    <Button onClick={() => { setTimeOffset(timeOffset + 1000 * 60) }}>+1 minute</Button>
    <Button onClick={() => { setTimeOffset(timeOffset + 1000 * 60 * 60) }}>+1 hour</Button>
    <Button onClick={() => { setTimeOffset(timeOffset + 1000 * 60 * 60 * 24) }}>+1 day</Button>
  </div>
  <div>
    <Button onClick={() => { setTimeOffset(timeOffset - 1000) }}>-1 second</Button>
    <Button onClick={() => { setTimeOffset(timeOffset - 1000 * 10) }}>-10 seconds</Button>
    <Button onClick={() => { setTimeOffset(timeOffset - 1000 * 60) }}>-1 minute</Button>
    <Button onClick={() => { setTimeOffset(timeOffset - 1000 * 60 * 60) }}>-1 hour</Button>
    <Button onClick={() => { setTimeOffset(timeOffset - 1000 * 60 * 60 * 24) }}>-1 day</Button>
  </div>
  <Button onClick={() => { setTimeOffset(0) }}>Reset to back to zero</Button>
</div>



 */
