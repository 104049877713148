import React from 'react'
import { Button } from 'semantic-ui-react'
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import './render-controls.css';
import {
  setLineWidth,
  setEarthTextureOpacity,
  setEarthWireFrameOpacity,
  setOrbitPathOpacity
} from 'renderSettingsReducer/actions'


const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};




const lineWidthOptions = [
  1,
  2,
  4,
  8
]

const RenderControls = React.memo(({
  lineWidth,
  earthTextureOpacity,
  earthWireFrameOpacity,
  orbitPathOpacity,
  renderSettingsDispatch
}) => {
  const slidersWidth = window.innerWidth <= 796 ? 200 : 70
  return (
    <div className='render-controls'>
      <p className='label'>Line width</p>
      <div className='line-width-options'>
      {lineWidthOptions.map(option => {
        return (
          <div className='button-container' key={option}>
          <Button
            size="tiny"
            toggle active={lineWidth === option}
            onClick={() => { renderSettingsDispatch(setLineWidth(option)) }}
            >
            {option}
          </Button>
          </div>
        )
      })}
      </div>
      <div className='opacity-controls'>
        <p>Earth texture opacity</p>
        <div>
          <Slider
            style={{width: slidersWidth}}
            onChange={(value) => { renderSettingsDispatch(setEarthTextureOpacity(value/100)) }}
            min={0}
            max={100}
            defaultValue={earthTextureOpacity * 100}
            handle={handle} />
        </div>
        <p>Earth wireframe opacity</p>
        <div>
        <Slider
          style={{width: slidersWidth}}
          onChange={(value) => { renderSettingsDispatch(setEarthWireFrameOpacity(value/100)) }}
          min={0}
          max={100}
          defaultValue={earthWireFrameOpacity * 100}
          handle={handle} />
        </div>
        <p>Orbit path opacity</p>
        <div>
        <Slider
          style={{width: slidersWidth}}
          onChange={(value) => { renderSettingsDispatch(setOrbitPathOpacity(value/100)) }}
          min={0}
          max={100}
          defaultValue={orbitPathOpacity * 100}
          handle={handle} />
        </div>
      </div>

    </div>
  )
})

export default RenderControls
