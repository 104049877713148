import moment from 'moment'
// eslint-disable-next-line import/no-webpack-loader-syntax
// import SpaceTrackTLEWorker from 'workerize-loader!../workers/SpaceTrackTLEWorker'

// eslint-disable-next-line import/no-webpack-loader-syntax
import UCSSatDatabaseWorker from 'workerize-loader!../workers/UCSSatDatabaseWorker'

// eslint-disable-next-line import/no-webpack-loader-syntax
// import CelestrakTLEWorker from 'workerize-loader!../workers/CelestrakTLEWorker'

// eslint-disable-next-line import/no-webpack-loader-syntax
import SpaceObjectsWorker from 'workerize-loader!../workers/SpaceObjectsWorker'

let dayQueryString = moment().format("YYMMDDHH")

export const datafiles = [
  {
    filepath: `/UCS-Satellite-Database-10-1-19.csv?${dayQueryString}`,
    lastUpdated: new Date(10,1, 2019),
    name: "UCSsatDb",
    source: "https://www.ucsusa.org/resources/satellite-database",
    shouldLoad: true,
    worker: UCSSatDatabaseWorker
  },
  // {
  //   filepath: '/tle-13-january-2020.txt',
  //   lastUpdated: new Date(4,1, 2019),
  //   name: "spaceTrackTLE",
  //   source: "https://www.space-track.org/",
  //   shouldLoad: true,
  //   worker: SpaceTrackTLEWorker
  // },
  // {
  //   filepath: '/celestrak-catalog-2020-02-17.txt',
  //   lastUpdated: new Date(17,2, 2020),
  //   name: "celestrakTLE",
  //   source: "https://www.celestrak.com/",
  //   shouldLoad: true,
  //   worker: CelestrakTLEWorker
  // },
  {
    filepath: `/space-objects-latest.csv?${dayQueryString}`,
    lastUpdated: new Date(11,3, 2020),
    name: "spaceObjects",
    source: "https://www.celestrak.com/",
    shouldLoad: true,
    worker: SpaceObjectsWorker
  }
]


export const loadingStates = {
  LOADING: "LOADING",
  AVAILABLE: "AVAILABLE",
  UNAVAILABLE: "UNAVAILABLE"
}
