import ReactGA from 'react-ga'
import {
  ORBIT_TYPE,
  ORBIT_HEIGHT,
  ORBIT_DURATION,
  LAUNCH_YEAR,
  REVOLUTIONS,
  MASS,
  CONTRACTOR,
  CLASSIFICATION,
  SEARCH
} from '../filterReducer/constants'
import _ from 'lodash'

export function logAction(action) {
  if(window.location.href.includes('space-search.io')) {
    if(!window.location.href.includes('?goal=conf-demo-page')) {
      let category = "UNDEFINED"
      if(action.type.startsWith(SEARCH)) {
        category = SEARCH
      } else if(action.type.startsWith(ORBIT_TYPE)) {
        category = ORBIT_TYPE
      } else if(action.type.startsWith(ORBIT_HEIGHT)) {
        category = ORBIT_HEIGHT
      } else if(action.type.startsWith(ORBIT_DURATION)) {
        category = ORBIT_DURATION
      } else if(action.type.startsWith(LAUNCH_YEAR)) {
        category = LAUNCH_YEAR
      } else if(action.type.startsWith(REVOLUTIONS)) {
        category = REVOLUTIONS
      } else if(action.type.startsWith(MASS)) {
        category = MASS
      } else if(action.type.startsWith(CONTRACTOR)) {
        category = CONTRACTOR
      } else if(action.type.startsWith(CLASSIFICATION)) {
        category = CLASSIFICATION
      } else {
        ReactGA.event({
          category: action.type,
          action: action.type,
          value: _.get(action, 'payload', false),
          label: _.get(action, 'payload', false)
        });
      }
      ReactGA.event({
        category: category,
        action: action.type,
        value: _.get(action, 'payload', false),
        label: _.get(action, 'payload', false)
      })
    }
  }
}

export function logRenderSettingsReducerAction(action) {
  if(window.location.href.includes('space-search.io')) {
    if(!window.location.href.includes('?goal=conf-demo-page')) {
      ReactGA.event({
        category: "renderSettingsReducer",
        action: action.type,
        value: _.get(action, 'payload', false),
        label: _.get(action, 'payload', false)
      });
    }
  }
}

export function simpleLogAction(category, action, value, label) {
  if(window.location.href.includes('space-search.io')) {
    if(!window.location.href.includes('?goal=conf-demo-page')) {
      let logEvent = {}
      if(category !== undefined) {
        logEvent.category = category
      }
      if(action !== undefined) {
        logEvent.action = action
      }
      if(value !== undefined) {
        logEvent.value = value
      }
      if(label !== undefined) {
        logEvent.label = label
      }
      ReactGA.event(logEvent)
    }
  }
}
