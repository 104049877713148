import { Vec2 } from 'ogl'
function satMouseDistance(sat, lastMousePosVec, camera) {
  let canCalculateDistance = true
  // if(sat.positionAvailable === false) {
  //   canCalculateDistance = false
  // }
  // if(sat.pointPosAvailable === false) {
  //   canCalculateDistance = false
  // }
  if(sat.vecPos === undefined) {
    canCalculateDistance = false
  }
  let mouseDistance = 1000
  let projectedVec = new Vec2(-100,100)
  if(canCalculateDistance) {
    projectedVec = sat.vecPos.slice()
    camera.project(projectedVec)
    mouseDistance = lastMousePosVec.distance([projectedVec.x,projectedVec.y])
  }

  return {
    vecPos: sat.vecPos,
    projectedVec: projectedVec,
    mouseDistance: mouseDistance,
    sat: sat
  }
}

export default satMouseDistance
