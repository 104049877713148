import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { sgp4WorkerInstance } from '../../workers/workers'
import './orbit-distance-comparison.css'
import DistanceComparisonGraph from './DistanceComparisonGraph'
import ComparisonRenderControls from './ComparisonRenderControls'
import { Button } from 'semantic-ui-react'
import { logAction } from '../../analytics/analytics'
let sats = []

const currentTime = new Date().getTime()

const OrbitDistanceComparison = React.memo(({
  satellites,
  compareOrbits,
  propagatedOrbits,
  setPropagatedOrbits,
  draggingOrbit,
  theme,
  compareAtInterval,
  setCompareAtInterval,
  orbitComparisonRenderType,
  renderSettingsDispatch
}) => {
  const [revolutions, _setRevolutions] = useState(0.5)
  const [latestUpdateTime, setLatestUpdateTime] = useState(0)


  let className= 'orbit-distance-comparison'
  if(compareOrbits && satellites.length > 1) className += " showing"
  let shouldUpdateSats = false
  if(!_.isEqual(sats.map(sat => sat.id), satellites.map(sat => sat.id))) {
    if(compareOrbits) {
      shouldUpdateSats = true
      sats = satellites
    }
  }
  function setRevolutions(revolutionNr) {
    sats = []
    logAction({type: "orbitComparison_setRevolutions", payload: revolutionNr})
    _setRevolutions(revolutionNr)
    setLatestUpdateTime(new Date().getTime())
  }
  useEffect(() => {
    if(shouldUpdateSats && satellites.length > 1 && compareOrbits) {
      let _sats = sats.slice()
      sgp4WorkerInstance.propagateSatelliteIDs(_sats.map(sat => sat.id),
        currentTime,
        'earthRotations',
        revolutions, // revolutions
        20/(revolutions/4) // stepFactor
      ).then(returnMessage => {
        if(returnMessage.success) {
          setPropagatedOrbits(returnMessage.data.map((sat, i) => {
            if(_.get(sat, 'id', 'no_id') === _.get(_sats[i], 'id', false)) {
              return {
                ..._sats[i],
                ...sat
              }
            } else {
              return false
            }
          }))
          setTimeout(function () {
            setLatestUpdateTime(new Date().getTime())
          }, 10);
        }
      })
    }
  }, [shouldUpdateSats, compareOrbits, satellites.length, revolutions, setPropagatedOrbits])
  if(satellites.length < 2) {
    return (
      <div className={className}>
      <p>Select 2 satellites or more to compare distances.</p>
      </div>
    )
  }
  return (
    <div className={className}>
    <ComparisonRenderControls
      orbitComparisonRenderType={orbitComparisonRenderType}
      renderSettingsDispatch={renderSettingsDispatch}
      />
    {propagatedOrbits.length > 1 ? (
      <DistanceComparisonGraph
        latestUpdateTime={latestUpdateTime}
        propagatedOrbits={propagatedOrbits}
        draggingOrbit={draggingOrbit}
        theme={theme}
        compareAtInterval={compareAtInterval}
        setCompareAtInterval={setCompareAtInterval}
        />
    ) : (
      <p>Calculating orbit data.</p>
    )}

    <div className='controls'>
      <p>Set timeframe to compare:</p>
      <div className='button-row'>
      <Button size="mini" toggle active={revolutions === 2/24}
        onClick={() => setRevolutions(2/24)}
        >
        2 hours
      </Button>
      <Button size="mini" toggle active={revolutions === 4/24}
        onClick={() => setRevolutions(4/24)}
        >
        4 hours
      </Button>
      <Button size="mini" toggle active={revolutions === 0.5}
        onClick={() => setRevolutions(0.5)}
        >
        12 hours
      </Button>
      <Button size="mini" toggle active={revolutions === 1}
        onClick={() => setRevolutions(1)}
        >
        1 day
      </Button>
      <Button size="mini" toggle active={revolutions === 5}
        onClick={() => setRevolutions(5)}
        >
        5 days
      </Button>
      </div>
    </div>
    </div>
  )

})

export default OrbitDistanceComparison
