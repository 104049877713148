export const vertex300 = `#version 300 es
    precision highp float;
    precision highp int;
    in vec3 position;
    in vec2 uv;
    in vec3 normal;
    uniform mat3 normalMatrix;
    uniform mat4 modelMatrix;
    uniform mat4 modelViewMatrix;
    uniform mat4 projectionMatrix;
    out vec2 vUv;
    out vec3 vNormal;
    out vec3 vMPos;
    void main() {
        vUv = uv;
        vNormal = normalize(normalMatrix * normal);
        vMPos = (modelMatrix * vec4(position, 1.0)).xyz;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;

export const vertex100 = /* glsl */`
    precision highp float;
    precision highp int;
    attribute vec3 position;
    attribute vec2 uv;
    attribute vec3 normal;
    uniform mat3 normalMatrix;
    uniform mat4 modelMatrix;
    uniform mat4 modelViewMatrix;
    uniform mat4 projectionMatrix;
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vMPos;
    void main() {
        vUv = uv;
        vNormal = normalize(normalMatrix * normal);
        vMPos = (modelMatrix * vec4(position, 1.0)).xyz;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;

export const fragment300 = `#version 300 es
    precision highp float;
    precision highp int;
    uniform mat4 viewMatrix;
    uniform float uTime;
    uniform sampler2D tDiffuse;
    uniform sampler2D tNormal;
    uniform float uNormalScale;
    uniform float uNormalUVScale;
    uniform float uOpacity;
    in vec2 vUv;
    in vec3 vNormal;
    in vec3 vMPos;
    out vec4 FragColor;
    vec3 getNormal() {
        vec3 pos_dx = dFdx(vMPos.xyz);
        vec3 pos_dy = dFdy(vMPos.xyz);
        vec2 tex_dx = dFdx(vUv);
        vec2 tex_dy = dFdy(vUv);
        vec3 t = normalize(pos_dx * tex_dy.t - pos_dy * tex_dx.t);
        vec3 b = normalize(-pos_dx * tex_dy.s + pos_dy * tex_dx.s);
        mat3 tbn = mat3(t, b, normalize(vNormal));
        vec3 n = texture(tNormal, vUv * uNormalUVScale).rgb * 2.0 - 1.0;
        n.xy *= uNormalScale;
        vec3 normal = normalize(tbn * n);
        // Get world normal from view normal
        return normalize((vec4(normal, 0.0) * viewMatrix).xyz);
    }
    void main() {
        vec3 tex = texture(tDiffuse, vUv).rgb;
        vec3 normal = getNormal();

        vec3 light = normalize(vec3(0.0, 0.0, 1.0));
        float shading = dot(normal, light) * 0.15 +0.3;

        FragColor.rgb = tex + shading;
        FragColor.a = uOpacity;
    }
`;

export const fragment100 = `#extension GL_OES_standard_derivatives : enable
#extension OES_texture_float : enable
#extension OES_texture_half_float : enable
            precision highp float;
            precision highp int;
            uniform mat4 viewMatrix;
            uniform float uTime;
            uniform sampler2D tDiffuse;
            uniform sampler2D tNormal;
            uniform float uNormalScale;
            uniform float uNormalUVScale;
            uniform float uOpacity;
            varying vec2 vUv;
            varying vec3 vNormal;
            varying vec3 vMPos;
            vec3 getNormal() {
                vec3 pos_dx = dFdx(vMPos.xyz);
                vec3 pos_dy = dFdy(vMPos.xyz);
                vec2 tex_dx = dFdx(vUv);
                vec2 tex_dy = dFdy(vUv);
                vec3 t = normalize(pos_dx * tex_dy.t - pos_dy * tex_dx.t);
                vec3 b = normalize(-pos_dx * tex_dy.s + pos_dy * tex_dx.s);
                mat3 tbn = mat3(t, b, normalize(vNormal));
                vec3 n = texture2D(tNormal, vUv * uNormalUVScale).rgb * 2.0 - 1.0;
                n.xy *= uNormalScale;
                vec3 normal = normalize(tbn * n);
                // Get world normal from view normal
                return normalize((vec4(normal, 0.0) * viewMatrix).xyz);
            }
            void main() {
                vec3 tex = texture2D(tDiffuse, vUv).rgb;
                vec3 normal = getNormal();

                vec3 light = normalize(vec3(0.0, 0.0, 1.0));
                float shading = dot(normal, light) * 0.15 +0.3;

                gl_FragColor.rgb = tex + shading;
                gl_FragColor.a = uOpacity;
            }
        `;
